import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Setting.css";
import { useLocation, useSearchParams } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { MdOutlineMarkEmailUnread } from "react-icons/md";

const Setting = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const room_id = searchParams.get("room_id");
  const [roomData, setRoomData] = useState({
    roomName: "",
    email: "xyz@gmail.com",
    roomId: room_id || "",
  });

  const [loading, setLoading] = useState(false);

  console.log("Room ID:", roomData.roomId);

  const [userSettings, setUserSettings] = useState({
    requiresSignIn: true,
    allowRecording: true,
    requireApproval: true,
    allUsersModerators: true,
    allowAnyUserToStart: false,
    muteOnJoin: false,
  });
  const navigate = useNavigate();
  const handleToggle = (settingKey) => {
    setUserSettings((prev) => ({
      ...prev,
      [settingKey]: !prev[settingKey],
    }));
  };

  const handleSave = async () => {
    const { roomName, roomId } = roomData;
    if (!roomName || !roomId) {
      alert("Please fill in all fields.");
      return;
    }

    setLoading(true);
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_REST_URL}/vc/update-room?room_id=${roomData.roomId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            room_id: roomData.roomId,
            name: roomData.roomName,
          }),
        }
      );

      if (response.ok) {
        alert("Room updated successfully.");
        navigate("/vc");
      } else {
        alert("Failed to update room.");
      }
    } catch (error) {
      alert("Error updating room.");
    } finally {
      setLoading(false);
    }
  };

  const handleBackButtonClick = () => {
    navigate("/vc");
  };

  const deleteRoom = async () => {
    const roomId = roomData.roomId;
    console.log(roomData.roomId);
    if (!roomId) {
      alert("Room ID is missing.");
      return;
    }

    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_REST_URL}/vc/delete-room?room_id=${roomData.roomId}`,
        {
          method: `DELETE`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        navigate("/vc");
      } else {
        alert("failed to delete");
      }
    } catch (error) {
      alert("failed to delete");
    }
  };

  const handleCancel = () => {
    navigate("/vc");
  };

  return (
    <header className="setting-container">
      <img
        className="setting-logo"
        src="../../Images/Logo.png"
        alt="Beep Logo"
      />
      <div className="setting-bar">
        <IoArrowBackCircleOutline
          className="back-svg"
          style={{
            padding: "40px",
            transition: "transform 0.3s ease, fill 0.3s ease",
            fontSize: "30px",
            color: "#fff",
            cursor: "pointer",
          }}
          onClick={handleBackButtonClick}
        />

        <h1 className="setting-title">Settings</h1>
      </div>

      <div className="body-part">
        <div className="body-heading-del-btn">
          <h2>{roomData.roomName || "Room Settings"}</h2>
          <button onClick={() => deleteRoom()} className="delete-button">
            DELETE
          </button>
        </div>

        <div className="setting-footer">
          <button onClick={handleCancel} className="cancel-button">
            Cancel
          </button>
          <button onClick={handleSave} className="save-button">
            Save
          </button>
        </div>
      </div>

      <div className="setting-content">
        <div className="room-info-panel">
          <div className="form-group">
            <label>Room Name</label>
            <input
              type="text"
              placeholder="Enter room name"
              value={roomData.roomName}
              onChange={(e) =>
                setRoomData({ ...roomData, roomName: e.target.value })
              }
              className="input-field"
            />
          </div>

          <div className="form-group-1">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter email"
              value={roomData.email}
              onChange={(e) =>
                setRoomData({ ...roomData, email: e.target.value })
              }
              className="input-field-mail"
            />
            <MdOutlineMarkEmailUnread
              className="mail-icon"
              style={{ color: "#e8eaed" }}
            />
          </div>

          <div className="form-group">
            <label>Share Access</label>
            <select className="input-field">
              <option>Video Confrenceing</option>
              <option>Internal meeting</option>
              <option>Discussion</option>
            </select>
          </div>

          <div className="form-group">
            <label>Upload Room Icon</label>
            <div className="upload-area">
              Click to upload or drag and drop<br></br>
              <span>SVG, PNG, JPG or GIF (max 800x400px)</span>
            </div>
          </div>
        </div>

        <div className="user-settings-panel">
          <div className="settings-toggle">
            <div
              className={`toggle-button ${userSettings.requiresSignIn ? "active" : ""
                }`}
              onClick={() => handleToggle("requiresSignIn")}
            >
              <div className="toggle-circle"></div>
            </div>
            <div className="labeltoggle-1">
              <label>Requires users to sign in before joining</label>
              <label style={{ fontSize: "12px" }}>
                Autopaycut occurs at the end of each month
              </label>
            </div>
          </div>

          <div className="settings-toggle">
            <div
              className={`toggle-button ${userSettings.allowRecording ? "active" : ""
                }`}
              onClick={() => handleToggle("allowRecording")}
            >
              <div className="toggle-circle"></div>
            </div>
            <div className="labeltoggle-2">
              <label>Allow room to be recorded</label>
              <label style={{ fontSize: "12px" }}>
                You will be notified when a payment has been made
              </label>
            </div>
          </div>

          <div className="settings-toggle">
            <div
              className={`toggle-button ${userSettings.requireApproval ? "active" : ""
                }`}
              onClick={() => handleToggle("requireApproval")}
            >
              <div className="toggle-circle"></div>
            </div>
            <div className="labeltoggle-3">
              <label>Require moderator approval before joining</label>
              <label style={{ fontSize: "12px" }}>
                You will be notified when a payment has been made
              </label>
            </div>
          </div>

          <div className="settings-toggle">
            <div
              className={`toggle-button ${userSettings.allUsersModerators ? "active" : ""
                }`}
              onClick={() => handleToggle("allUsersModerators")}
            >
              <div className="toggle-circle"></div>
            </div>
            <div className="labeltoggle-4">
              <label>All users join as moderators</label>
              <label style={{ fontSize: "12px" }}>
                You will be notified when a payment has been made
              </label>
            </div>
          </div>

          <div className="settings-toggle">
            <div
              className={`toggle-button ${userSettings.allowAnyUserToStart ? "active" : ""
                }`}
              onClick={() => handleToggle("allowAnyUserToStart")}
            >
              <div className="toggle-circle"></div>
            </div>
            <div className="labeltoggle-5">
              <label>Allow any user to start this meeting</label>
              <label style={{ fontSize: "12px" }}>
                You will be notified when a payment has been made
              </label>
            </div>
          </div>

          <div className="settings-toggle">
            <div
              className={`toggle-button ${userSettings.muteOnJoin ? "active" : ""
                }`}
              onClick={() => handleToggle("muteOnJoin")}
            >
              <div className="toggle-circle"></div>
            </div>
            <div className="labeltoggle-6">
              <label>Mute users when they join</label>
              <label style={{ fontSize: "12px" }}>
                You will be notified when a payment has been made
              </label>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Setting;
