import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./RoomCard.css";
import SearchBar from "./SearchBar";
import moment from "moment";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { MdOutlineModeEdit } from "react-icons/md";
import { GrSettingsOption } from "react-icons/gr";

const RoomCard = (props) => {
  console.log(props);
  const {
    searchInput,
    setSearchInput,
    isLoading,
    setIsLoading,
    rooms,
    setRooms,
  } = props;
  const navigate = useNavigate();
  const [dropdownOpenId, setDropdownOpenId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [roomImages, setRoomImages] = useState(() => {
    const savedImages = localStorage.getItem("roomImages");
    return savedImages ? JSON.parse(savedImages) : {};
  });

  const toggleDropdown = (e, roomId) => {
    e.stopPropagation();
    setDropdownOpenId(dropdownOpenId === roomId ? null : roomId);
  };

  const handleEditClick = (room, e) => {
    e.stopPropagation();
    setSelectedRoom(room);
    setShowModal(true);
  };

  const closeModal = (e) => {
    e.stopPropagation();
    setShowModal(false);
    setSelectedRoom(null);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        !e.target.closest(".dropdown-menu") &&
        !e.target.closest(".dots-icon")
      ) {
        setDropdownOpenId(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("roomImages", JSON.stringify(roomImages));
  }, [roomImages]);

  // const images = Array.from(
  //   { length: 11 },
  //   (_, i) => ` /Images/image${i + 1}.png`
  // );

  const images = [
    "https://averox.com/wp-content/uploads/2025/01/image1.png",
    "https://averox.com/wp-content/uploads/2025/01/image2.png",
    "https://averox.com/wp-content/uploads/2025/01/image3.png",
    "https://averox.com/wp-content/uploads/2025/01/image4.png",
    "https://averox.com/wp-content/uploads/2025/01/image5.png",
    "https://averox.com/wp-content/uploads/2025/01/image6.png",
    "https://averox.com/wp-content/uploads/2025/01/image7.png",
    "https://averox.com/wp-content/uploads/2025/01/image8.png",
    "https://averox.com/wp-content/uploads/2025/01/image9.png",
    "https://averox.com/wp-content/uploads/2025/01/image10.png",
    "https://averox.com/wp-content/uploads/2025/01/image11.jpg",

  ];
  const handleImageSelect = (img, e) => {
    e.stopPropagation();
    if (selectedRoom) {
      setRoomImages((prev) => ({
        ...prev,
        [selectedRoom.id || selectedRoom._id || selectedRoom.room_id]: img,
      }));
      closeModal(e);
    }
  };

  const breakRoomName = (name, maxLength = 30) => {
    return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
  };

  return (
    <div className="room-card-container">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="room-card-grid">
          {rooms.map((room) => {
            const roomId = room.id || room._id || room.room_id;
            const formattedDate = room.created_at
              ? moment(room.created_at).format("MMMM D, YYYY [at] h:mm A")
              : "December 24, 2024 at 3:00 PM ";

            const lastSessionInfo = room.lastSession
              ? room.lastSession
              : "No previous session created";

            return (
              <div
                key={roomId}
                className="room-card"
                onClick={() =>
                  navigate(`/vc/room?room_id=${room.connection_string}`)
                }
              >
                <div className="three-dots-room">
                  <div className="dots-icon">
                    <HiOutlineDotsVertical
                      style={{
                        color: "rgb(180 173 173)",
                        background: "rgb(249 249 249)",
                        borderRadius: "50px",
                        padding: "10px",
                      }}
                      onClick={(e) => toggleDropdown(e, roomId)}
                    />
                  </div>
                  {dropdownOpenId === roomId && (
                    <div className="dropdown-menu-card-bottom">
                      <ul
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="dropdown-styling-card"
                      >
                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            alert("Link Copied");
                          }}
                          onMouseOver={(e) => {
                            e.target.style.background = "#edffee";
                            e.target.style.borderRadius = "8px";
                            e.target.style.color = "green ";
                          }}
                          onMouseOut={(e) => {
                            e.target.style.background = "transparent";
                            e.target.style.color = "#000 ";
                          }}
                        >
                          Link Copy
                        </li>
                        <li></li>
                      </ul>
                    </div>
                  )}
                </div>
                <div className="room-card-header">
                  <div
                    className="room-card-info"
                    style={{ position: "relative" }}
                  >
                    <img
                      className="img-pc-room"
                      src={roomImages[roomId] || "../../Images/image4.png"}
                      alt="room"
                    />
                    <MdOutlineModeEdit
                      onClick={(e) => handleEditClick(room, e)}
                      style={{
                        position: "absolute",
                        top: "55px",
                        right: "90px",
                        color: "green",
                        background: "white",
                        borderRadius: "50%",
                        padding: "1px",
                        border: "1px solid #ddd",
                        cursor: "pointer",
                      }}
                    />
                    <div className="session-room-name">
                      <div className="room-name">
                        {breakRoomName(room.name)}
                      </div>

                      <span>{lastSessionInfo}</span>
                      <span className="room-created-at">{formattedDate}</span>
                    </div>
                  </div>
                </div>
                <div className="footer-card">
                  <GrSettingsOption
                    className="rotate-icon"
                    style={{
                      cursor: "pointer",
                      width: "25px",
                      height: "25px",
                      color: "rgb(204 204 204)",
                    }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.color = "rgb(125, 161, 14)")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.color = "rgb(204 204 204)")
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log(props._id);
                      navigate(`/setting/room?room_id=${room._id}`, {
                        state: { room_id: props.roomId },
                      });
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}

      {showModal && (
        <div className="modal" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="image-grid">
              {images.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`img-${index}`}
                  onClick={(e) => handleImageSelect(img, e)}
                  style={{ cursor: "pointer" }}
                />
              ))}
            </div>
            <button className="close-btn" onClick={(e) => closeModal(e)}>
              X
            </button>
          </div>
        </div>
      )}

      <footer style={{ position: "absolute", right: "50px", bottom: "30px" }}>
        <SearchBar
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setRooms={setRooms}
          rooms={rooms}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
      </footer>
    </div>
  );
};

export default RoomCard;
