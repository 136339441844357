import React, { createContext, useEffect, useState, useMemo, useCallback } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";


const AppContext = createContext();

export default AppContext;

export const Provider = ({ children }) => {
  const [userChats, setUserCharts] = useState([])
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_REST_URL;
  const [loginLoader, setLoginLoader] = useState(false);
  const navigate = useNavigate();

  const getUserChatGroups = async () => {
    const accessToken = localStorage.getItem('accessToken')

    console.log(accessToken)

    const response = await fetch(`${process.env.REACT_APP_REST_URL}/im2/get-chatgroups`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
    });

    const data = await response.json()
    setUserCharts(data.chatGroups)
  }

  const [accessToken, setAccessToken] = useState(() =>
    localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : null
  );
  const [refreshToken, setRefreshToken] = useState(() =>
    localStorage.getItem("refreshToken") ? localStorage.getItem("refreshToken") : null
  );
  const [user, setUser] = useState(() =>
    accessToken ? jwtDecode(accessToken) : null
  );
  const [messages, setMessages] = useState({});
  const [socket, setSocket] = useState(null);

  const loginUser = async (e) => {
    e.preventDefault();
    if (!loginLoader) {
      setLoginLoader(true);
      const email = e.target?.email?.value;
      const password = e.target?.password?.value;

      let response = await fetch(`${REACT_APP_BACKEND_URL}/auth/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      let data = await response.json();

      if (response.ok) {
        setLoginLoader(false);
        setAccessToken(data.accessToken);
        setUser(jwtDecode(data.accessToken));
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        // connectSocket(data.accessToken); // Connect socket on login
        navigate("/");
      } else {
        setLoginLoader(false);
        alert("Incorrect credentials");
      }
    }
  };

  const updateToken = useCallback(async () => {
    let response = await fetch(`${REACT_APP_BACKEND_URL}/auth/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });
    let data = await response.json();
    if (response.status === 200) {
      setAccessToken(data.accessToken);
      setRefreshToken(data.refreshToken);
      setUser(jwtDecode(data.accessToken));
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
    } else {
      logoutUser();
      navigate("/login");
    }
  }, [refreshToken, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (accessToken && refreshToken) {
        updateToken();
      }
    }, 1000 * 60 * 23);

    return () => clearInterval(interval);
  }, [accessToken, refreshToken, updateToken]);

  const logoutUser = useCallback(() => {
    setAccessToken(null);
    setRefreshToken(null);
    setUser(null);
    setSocket(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    alert("Logout Successful");
    navigate("/login");
  }, [navigate]);

  useEffect(() => {
    if (user) {
      getUserChatGroups()
    }
  }, [user])


  const contextData = useMemo(
    () => ({
      user,
      logoutUser,
      loginUser,
      accessToken,
      refreshToken,
      loginLoader,
      userChats: userChats,
      setUserCharts: setUserCharts,

    }),
    [
      user,
      loginUser,
      logoutUser,
      accessToken,
      refreshToken,
      messages,
    ]
  );

  return <AppContext.Provider value={contextData}>{children}</AppContext.Provider>;
};
