import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import WhiteboardCanvas from './WhiteBoardCanvas'
import { IoMdMic, IoMdMicOff } from "react-icons/io";
import { HiVideoCamera, HiVideoCameraSlash } from "react-icons/hi2";


const VideoCall = (props) => {
  const {
    room_id,
    username,
    localVideoRef,
    isVideoMuted,
    isAudioMuted,
    localStream,
    sendMessage,
    toggleVideoMute,
    toggleAudioMute,
    peers,
    handleLeaveRoom,
    screenStream,
    socket,
    setScreenStream,
    screenPeers,
    screenShare,
    setScreenPeers,
    screenPeerRef,
    screenShareRef,
    pin,
    setPin,
    whiteBoard
  } = props

  const [pinnedPeer, setPinnedPeer] = useState({
    screenPeers: [],
    whiteBoardPeers: [],
  })

  const peerStreams = useRef({});

  const handleStreamReceived = (peerId, stream) => {
    peerStreams.current[peerId] = stream;
  };

  useEffect(() => {
    console.log("use effect")


    // When pin.pinned changes back to false, reattach the stream
    if (localVideoRef.current) {
      // Assuming you have access to the local stream
      localVideoRef.current.srcObject = localStream; // Store this stream in a ref or state
    }

    const screenPeers = peers?.filter(item => item.is_screensharing === true)?.slice(0, 1)
    const whiteBoardPeers = peers?.filter(item => item.sharing_whiteboard === true)?.slice(0, 1)

    setPinnedPeer({
      screenPeers: screenPeers,
      whiteBoardPeers: whiteBoardPeers
    })

  }, [pin.pinned]);

  return (
    <div
      id="vc_participants_master"
      style={((pin.pinned && !screenShare) || (pin.pinned && screenShare && peers.length > 0)) ? { gridTemplateColumns: "75% 23%" } : {}}
    >
      {((pin.pinned && !screenShare) || (pin.pinned && screenShare && peers.length > 0)) ?
        <>
          <div style={(screenShare || pinnedPeer.screenPeers.length > 0) ? { overflow: "hidden" } : {}} id='vc_participants_massive_child'>
            {whiteBoard &&
              <WhiteboardCanvas username={username} socket={socket} room_id={room_id} />
              // <div id='vc_participants_massive_canvas'>
              //   <div className="vc_participants_ite_name">
              //     {username}
              //   </div>
              //   <div id='vc_white_board_text'>
              //     White Board
              //   </div>
              // </div>
            }

            {!whiteBoard && pinnedPeer?.whiteBoardPeers?.map(({ peer, peer_id, username, audioMuted, videoMuted, sharing_whiteboard }) => {

              return (
                <WhiteboardCanvas username={username} socket={socket} room_id={room_id} />
                // <div id='vc_participants_massive_canvas'>
                //   <div className="vc_participants_ite_name">
                //     {username}
                //   </div>
                //   <div id='vc_white_board_text'>
                //     White Board
                //   </div>
                // </div>
              )
            })}





            {screenShare &&
              <div className='vc_participants_small_container'
              >
                <div style={pin?.pinned ? { borderRadius: "0px", position: "unset" } : {}} className='vc_participants_item' >
                  <div className="vc_participants_ite_name">
                    {username}
                  </div>
                  {isVideoMuted && (
                    <div style={{
                      position: 'absolute',
                      inset: 0,
                      backgroundColor: 'rgb(50,50,50)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white',
                      zIndex: "3"
                    }}>
                      <HiVideoCameraSlash style={{ color: "white", fontSize: "30px", minWidth: "30px" }} />
                    </div>
                  )}
                  {isAudioMuted && (
                    <IoMdMicOff style={{
                      position: "absolute",
                      bottom: "10px",
                      right: "10px",
                      zIndex: "4",
                      color: "rgb(255,255,255)",
                      fontSize: "20px",
                      minWidth: "20px"
                    }} />
                  )}
                  <span className="vc_participants_ite_loader"></span>
                  <video
                    className="vc_participants_ite_vid"
                    ref={localVideoRef}
                    autoPlay
                    muted
                    style={{
                      width: '100%',
                      height: "100%",
                      zIndex: "1",
                      objectFit: 'cover',
                      transform: screenShare ? 'none' : 'scaleX(-1)'
                    }}
                  />
                </div>
              </div>
            }

            {!screenShare && pinnedPeer?.screenPeers?.map(({ peer, peer_id, username, audioMuted, videoMuted, is_screensharing }) => {

              return (
                <div className="vc_participants_small_container" key={peer_id}>
                  <RemotePeer
                    pin={pin}
                    is_screensharing={is_screensharing}
                    peerID={peer_id}
                    audioMuted={audioMuted}
                    peer={peer}
                    username={username}
                    videoMuted={videoMuted}
                    peerStreams={peerStreams}
                    onStreamReceived={handleStreamReceived}
                  />
                </div>
              )
            })}

          </div>




          <div id='vc_participants_small_child'>
            {!screenShare &&
              <div className='vc_participants_small_container'
              >
                <div className='vc_participants_ite2' >
                  <div className="vc_participants_ite_name">
                    {username}
                  </div>
                  {isVideoMuted && (
                    <div style={{
                      position: 'absolute',
                      inset: 0,
                      backgroundColor: 'rgb(50,50,50)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white',
                      zIndex: "3"
                    }}>
                      <HiVideoCameraSlash style={{ color: "white", fontSize: "30px", minWidth: "30px" }} />
                    </div>
                  )}
                  {isAudioMuted && (
                    <IoMdMicOff style={{
                      position: "absolute",
                      bottom: "10px",
                      right: "10px",
                      zIndex: "4",
                      color: "rgb(255,255,255)",
                      fontSize: "20px",
                      minWidth: "20px"
                    }} />
                  )}
                  <span className="vc_participants_ite_loader"></span>
                  <video
                    className="vc_participants_ite_vid"
                    ref={localVideoRef}
                    autoPlay
                    muted
                    style={{
                      width: '100%',
                      height: "100%",
                      zIndex: "1",
                      objectFit: 'cover',
                      transform: screenShare ? 'none' : 'scaleX(-1)'
                    }}
                  />
                </div>
              </div>
            }

            {peers?.filter(item => pinnedPeer?.screenPeers?.[0]?.peer_id !== item?.peer_id)?.map(({ peer, peer_id, username, audioMuted, videoMuted, is_screensharing }) => {

              return (
                <div className="vc_participants_small_container" key={peer_id}>
                  <RemotePeer
                    pin={pin}
                    is_screensharing={is_screensharing}
                    peerID={peer_id}
                    audioMuted={audioMuted}
                    peer={peer}
                    username={username}
                    videoMuted={videoMuted}
                    peerStreams={peerStreams}
                    onStreamReceived={handleStreamReceived}
                  />
                </div>
              )
            })
            }
          </div>
        </>
        :
        <>
          <div className={`${(peers.length > 0 && peers.length < 2) ? "vc_participants_ite_float" : "vc_participants_container"}`}
          >
            <div className='vc_participants_item' >
              <div className="vc_participants_ite_name">
                {username}
              </div>
              {isVideoMuted && (
                <div style={{
                  position: 'absolute',
                  inset: 0,
                  backgroundColor: 'rgb(50,50,50)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  zIndex: "3"
                }}>
                  <HiVideoCameraSlash style={{ color: "white", fontSize: "30px", minWidth: "30px" }} />
                </div>
              )}
              {isAudioMuted && (
                <IoMdMicOff style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  zIndex: "4",
                  color: "rgb(255,255,255)",
                  fontSize: "20px",
                  minWidth: "20px"
                }} />
              )}
              <span className="vc_participants_ite_loader"></span>
              <video
                className="vc_participants_ite_vid"
                ref={localVideoRef}
                autoPlay
                muted
                style={{
                  width: '100%',
                  height: "100%",
                  zIndex: "1",
                  objectFit: 'cover',
                  transform: screenShare ? 'none' : 'scaleX(-1)'
                }}
              />
            </div>
          </div>

          {peers?.map(({ peer, peer_id, username, audioMuted, videoMuted, is_screensharing }) => {

            return (
              <div className="vc_participants_container" key={peer_id}>
                <RemotePeer
                  is_screensharing={is_screensharing}
                  peerID={peer_id}
                  audioMuted={audioMuted}
                  peer={peer}
                  username={username}
                  videoMuted={videoMuted}
                  peerStreams={peerStreams}
                  onStreamReceived={handleStreamReceived}
                />
              </div>
            )
          })
          }
        </>
      }
    </div >
  );
};

export default VideoCall;


function RemotePeer({
  peer,
  peerID,
  username,
  videoMuted,
  audioMuted,
  is_screensharing,
  onStreamReceived,
  peerStreams,
  pin
}) {
  const remoteVideoRef = useRef(null);

  console.log(remoteVideoRef)

  useEffect(() => {
    if (peer) {

      peer?.on('stream', stream => {
        if (remoteVideoRef.current) {
          console.log('starting save ref')
          remoteVideoRef.current.srcObject = stream;
          onStreamReceived(peerID, stream); // Store stream in parent
          console.log('saved ref')
        }
      });

      peer?.on('error', (err) => {
        console.error(`Peer connection error for peer ${peerID}:`, err);
      });
    }
  }, [peer, peerID]);

  useEffect(() => {
    const storedStream = peerStreams.current[peerID];
    if (remoteVideoRef.current && storedStream) {
      remoteVideoRef.current.srcObject = storedStream;
    }
  }, []);

  { console.log(pin?.pinned) }

  return (
    <div
      style={!pin?.pinned ? {} : (pin?.pinned && !is_screensharing) ? {} : { borderRadius: "0px", position: "unset" }}
      // className='vc_participants_item'
      className={`${!pin?.pinned ? "vc_participants_item" : (pin?.pinned && !is_screensharing) ? "vc_participants_ite2" : "vc_participants_item"}`}
    >
      <div className="vc_participants_ite_name">
        {username}
      </div>
      <span className="vc_participants_ite_loader"></span>
      <video
        className="vc_participants_ite_vid"
        ref={remoteVideoRef}
        autoPlay
        style={{
          zIndex: "1",
          width: '100%',
          height: "100%",
          objectFit: 'cover',
          transform: is_screensharing ? 'none' : 'scaleX(-1)'
        }}
      />
      {videoMuted && (
        <div style={{
          position: 'absolute',
          inset: 0,
          backgroundColor: 'rgb(50,50,50)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          zIndex: "3"
        }}>
          <HiVideoCameraSlash style={{ color: "white", fontSize: "30px", minWidth: "30px" }} />
        </div>
      )}
      {audioMuted && (
        <IoMdMicOff style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          zIndex: "4",
          color: "rgb(255,255,255)",
          fontSize: "20px",
          minWidth: "20px"
        }} />
      )}
    </div>
  );
}