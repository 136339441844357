import '../style.css'
import { BsCameraVideo } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { BsEmojiSmile } from "react-icons/bs";
import { IoMdSend } from "react-icons/io";
import { FaRegSmile } from "react-icons/fa";
import { PiPlus } from "react-icons/pi";
import im_background from '../../../assets/im_background.jpg'
import { IoIosSearch } from "react-icons/io";
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import AppContext from '../../AppContext';


const ChatHistory = (props) => {
    const { chatGroup_id, socket, lastMessage } = props
    const { user } = useContext(AppContext)

    const messagesEndRef = useRef(null)

    const [chatInfo, setChatInfo] = useState(null)
    const [chatMessages, setChatMessages] = useState([])


    const getChatGroupInfo = async (text) => {
        const accessToken = localStorage.getItem('accessToken')

        console.log(accessToken)

        const response = await fetch(`${process.env.REACT_APP_REST_URL}/im2/get-chatgroup-info?chatGroup_id=${chatGroup_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        });

        const data = await response.json()
        setChatInfo(data.chatGroup)
    }

    useEffect(() => {
        if (chatInfo) {
            setChatMessages(chatInfo.messages)
        }
    }, [chatInfo])

    useEffect(() => {
        if (lastMessage) {
            console.log(lastMessage)
            const newArr = [...chatMessages, lastMessage];
            setChatMessages(newArr)
        }
    }, [lastMessage])

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [chatMessages])

    useEffect(() => {
        if (user) {
            getChatGroupInfo()
        }
    }, [user, chatGroup_id])

    useEffect(() => {

    }, [])


    return (
        <>
            <div id='chat_history_head_master'>
                <div id='chat_history_head_child_img_master'>
                    <img id='chat_history_head_child_img_master' src='https://images.unsplash.com/photo-1736148912326-aeeda15df88f?q=80&w=3164&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' />
                </div>
                <span id='chat_history_nam_title'>
                    {chatInfo?.users?.length > 2 ?
                        <>{chatInfo?.users.map(user => user.username).join(', ')}</>
                        :
                        <>{chatInfo?.users?.filter(item => item.email !== user.email)[0].username}</>
                    }
                </span>
                <div id='chat_history_nam_call_ico_container'>
                    <BsCameraVideo id='chat_history_nam_call_icon' />
                </div>
                <div id='chat_history_nam_call_ico_container'>
                    <IoIosSearch id='chat_history_nam_call_icon' />
                </div>
            </div>
            <div id='chat_history_chats_bg'>
            </div>
            <div id='chat_history_chats_master'>
                {chatMessages?.map((data, index) => {
                    return (
                        <>
                            {data?.user?._id === user.id ?
                                <div key={index} className='chat_history_chats_chil_me'>{data?.message}</div>
                                :
                                <div key={index} className='chat_history_chats_chil_other'>{data?.message}</div>
                            }
                        </>
                    )
                })}
                <div ref={messagesEndRef} />
            </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    const newMsg = {
                        _id: "new",
                        date: new Date(),
                        user: { username: user.username, _id: user.id },
                        message: e.target.message_input.value,
                    };

                    console.log(newMsg);

                    // Clone the array and append the new message
                    const newArr = [...chatMessages, newMsg];

                    console.log(newArr);

                    // Set the new state with the updated array
                    setChatMessages(newArr);

                    socket.emit('send_message', {
                        chatGroup_id: chatGroup_id,
                        accessToken: localStorage.getItem('accessToken'),
                        username: user.username,
                        socket_id: socket.id,
                        message: e.target.message_input.value,
                    });

                    // Optionally clear the input field after sending the message
                    e.target.reset();
                }}
                id="chat_history_type_master"
            >
                <div id="chat_history_type_child_plus_master">
                    <PiPlus id="chat_history_type_child_plus_icon" />
                </div>
                <div id="chat_history_type_child_inp_master">
                    <input
                        name="message_input"
                        spellCheck="false"
                        id="chat_history_type_child_inp_inp"
                    />
                    <div id="chat_history_type_child_inp_smile_master">
                        <FaRegSmile id="chat_history_type_child_inp_smile_icon" />
                    </div>
                </div>
                <button type="submit" id="chat_history_type_child_send_master">
                    <IoMdSend id="chat_history_type_child_send_icon" />
                </button>
            </form>

        </>
    )
}

export default ChatHistory