import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./RoomCard.css";
import RoomCard from "./RoomCard";
import { CiSearch } from "react-icons/ci";

const VideoCall = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [activeTab, setActiveTab] = useState("Rooms");
  const [rooms, setRooms] = useState([]);
  const [filteredRooms, setFilteredRooms] = useState([]);
  const [userData, setUserData] = useState({
    name: "Administrator",
    imageUrl: "../../../Images/user.png",
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [signOutMessage, setSignOutMessage] = useState("");
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleHome = () => {
    navigate("/");
  };

  const handleSignOut = () => {
    setSignOutMessage("You have been signed out.");
    navigate("/login");
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(";").forEach((cookie) => {
      document.cookie =
        cookie.trim().split("=")[0] +
        "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
    });

    window.location.reload();
  };

  const fetchApiData = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        console.error("Access token not found in localStorage");
        return;
      }

      const res = await fetch(
        `${process.env.REACT_APP_REST_URL}/vc/get-all-rooms`,
        {
          method: "GET",
          headers: {
            "ngrok-skip-browser-warning": "true",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const data = await res.json();
      console.log("API Response:", data);
      if (data.rooms) {
        setRooms(data.rooms);
        setFilteredRooms(data.rooms);
        setIsLoading(false);
      } else {
        console.warn("No 'rooms' key in API response");
      }
    } catch (error) {
      console.error("Error fetching API data:", error);
    }
  };

  useEffect(() => {
    fetchApiData();
  }, []);

  useEffect(() => {
    const filtered = rooms.filter((room) =>
      room.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredRooms(filtered);
  }, [searchInput, rooms]);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleSearch = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div style={{ background: "#f5f5f5", height: "100vh", overflow: "hidden" }}>
      <header
        style={{
          background: "#ffffff",
          padding: "5px 30px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          position: "relative",
          zIndex: 10,
        }}
      >
        <div className="container">
          <img
            onClick={handleHome}
            className="logo-img"
            src="../../../Images/Logo.png"
            alt="beeplogo"
            style={{ height: "60px", width: "100px", cursor: "pointer" }}
          />
          <div
            style={{
              display: "flex ",
              gap: "20px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                background: "#edffee",
                padding: isExpanded ? "3px" : "10px",
                borderRadius: "16px",
                cursor: "pointer",
                width: isExpanded ? "300px" : "50px",
                transition: "width 0.3s ease",
                flexDirection: "row-reverse",
              }}
              onClick={toggleSearch}
            >
              <CiSearch
                style={{
                  height: "26px",
                  width: "26px",
                  color: "#4B882B",
                }}
              />
              {isExpanded && (
                <input
                  style={{
                    width: "100%",
                    padding: "10px 40px 10px 10px",
                    outline: "none",
                    borderRadius: "10px",
                    border: "none",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    fontSize: "16px",
                    cursor: "text",
                  }}
                  placeholder="Search rooms..."
                  type="search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                position: "relative",
                flexDirection: "row",
              }}
            >
              <img
                style={{ borderRadius: "50px" }}
                src={userData.imageUrl}
                alt="user"
              />
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  cursor: "pointer",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column-reverse",
                }}
                onClick={toggleDropdown}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#bfb8b8"
                >
                  <path d="M480-345 240-585l56-56 184 183 184-183 56 56-240 240Z" />
                </svg>
                <span
                  style={{
                    color: "grey",
                    fontWeight: "none",
                    fontSize: "18px",
                  }}
                >
                  {userData.name}
                </span>
                {isDropdownOpen && (
                  <ul
                    style={{
                      position: "absolute",
                      top: "100%",
                      right: "0",
                      background: "#fff",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      listStyle: "none",
                      padding: "10px",
                      margin: "10px 0 0 0",
                      boxShadow:
                        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                      zIndex: 1000,
                      display: "flex",
                      flexDirection: "column",
                      width: "200px",
                    }}
                  >
                    <li
                      style={{
                        padding: "5px 10px",
                        cursor: "pointer",
                        borderBottom: "1px solid #ddd",
                      }}
                      onClick={() => navigate("/Profile")}
                      onMouseOver={(e) => {
                        e.target.style.background = "#edffee";
                        e.target.style.borderRadius = "8px";
                        e.target.style.color = "green ";
                      }}
                      onMouseOut={(e) => {
                        e.target.style.background = "transparent";
                        e.target.style.color = "#000 ";
                      }}
                    >
                      Profile
                    </li>
                    <li
                      style={{
                        padding: "5px 10px",
                        cursor: "pointer",
                        borderBottom: "1px solid #ddd",
                        paddingBottom: "10px",
                      }}
                      onClick={() => navigate("/admin")}
                      onMouseOver={(e) => {
                        e.target.style.background = "#edffee";
                        e.target.style.borderRadius = "8px";
                        e.target.style.color = "green ";
                      }}
                      onMouseOut={(e) => {
                        e.target.style.background = "transparent";
                        e.target.style.color = "#000 ";
                      }}
                    >
                      Administrator
                    </li>
                    <hr class="dropdown-divider" role="separator"></hr>
                    <button
                      style={{
                        padding: "6px 20px",
                        cursor: "pointer",
                        color: "#fff",
                        background: "#7da10e",
                        borderRadius: "5px",
                        border: "none",
                        transition: "background 0.3s ease, transform 0.2s ease",
                        outline: "none",
                      }}
                      onClick={handleSignOut}
                      onMouseOver={(e) => {
                        e.target.style.background = "#7da10e";
                        e.target.style.transform = "scale(1.05)";
                      }}
                      onMouseOut={(e) => {
                        e.target.style.background = "#7da10e";
                        e.target.style.transform = "scale(1)";
                      }}
                    >
                      Sign Out
                    </button>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>

      <body>
        {activeTab === "Rooms" && (
          <div>
            <RoomCard
              setRooms={setRooms}
              rooms={filteredRooms}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </div>
        )}
      </body>
    </div>
  );
};

export default VideoCall;
