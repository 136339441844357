import '../style.css'
import { IoChatbubblesOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";


const NavigationOptions = () => {
    return (
        <div id='navigation_options_master'>
            <div style={{ background: "rgb(220, 220, 220)" }} className='navigation_options_child'>
                <IoChatbubblesOutline className='navigation_options_chil_icon' />
            </div>

            <div className='navigation_options_child'>
                <IoCallOutline className='navigation_options_chil_icon' />
            </div>
        </div>
    )
}

export default NavigationOptions