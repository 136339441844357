import React, { useState, useEffect, useContext, useRef } from 'react';
import io from 'socket.io-client';
import { useLocation, useSearchParams } from 'react-router-dom';
import AppContext from '../AppContext';
import Initial from './Initial'
import Conversation from './Conversation'


function Socket() {
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}/vc`, {
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
    });

    const currentScreenShareState = useRef({
        stream: null,
        isSharing: false,
        previousVideoState: false
    });
    let { user, accessToken } = useContext(AppContext);

    const [isHost, setIsHost] = useState(false);
    const [isOwner, setIsOwner] = useState(false);

    const [pin, setPin] = useState({
        type: "",
        peer_id: null,
        pinned: false,
    });

    const [activePopupIndex, setActivePopupIndex] = useState(null);

    const [messageList, setMessageList] = useState([])

    const [inMeetingNotification, setInMeetingNotification] = useState('')

    const location = useLocation();

    const localVideoRef = useRef(null);
    const [localStream, setLocalStream] = useState(null);

    const [whiteBoard, setWhiteBoard] = useState(false);

    const [isVideoMuted, setIsVideoMuted] = useState(false);
    const [screenShare, setScreenShare] = useState(false);
    const [screenRecording, setScreenRecording] = useState(false);

    const [handRaise, setHandRaise] = useState(false);

    const [isAudioMuted, setIsAudioMuted] = useState(false);

    const [peers, setPeers] = useState(null)
    const peersRef = useRef(null);

    const [hasJoined, setHasJoined] = useState(false);
    const [username, setUsername] = useState(user?.username ? user?.username : "");

    const [searchParams, setSearchParams] = useSearchParams();
    const room_id = searchParams.get("room_id")

    const [screenStream, setScreenStream] = useState(null);
    const [screenPeers, setScreenPeers] = useState([]);
    const screenPeerRef = useRef(null);

    useEffect(() => {
        peersRef.current = peers
    }, [peers])

    useEffect(() => {
        const screenSharingPeers = peers?.filter(item => item.is_screensharing === true)
        const whiteBoardPeers = peers?.filter(item => item.sharing_whiteboard === true)

        if (!pin.pinned && (screenSharingPeers?.length > 0 || whiteBoardPeers?.length > 0 || whiteBoard || screenShare)) {
            setPin((prevPin) => ({
                ...prevPin,
                type: whiteBoard ? "whiteBoard" : "screenShare",
                // peer_id: screenSharingPeers.length>0 && ,
                pinned: true,
            }));

            console.log('pin set')
        }

        if (pin.pinned && screenSharingPeers?.length === 0 && whiteBoardPeers?.length === 0 && !whiteBoard && !screenShare) {
            setPin((prevPin) => ({
                ...prevPin,
                type: "",
                pinned: false,
            }));

            console.log('pin unset')
        }
    }, [peers, whiteBoard, screenShare]);


    const handleBeforeUnload = () => {
        const videoTracks = localStream?.getVideoTracks();

        // Stop all existing video tracks
        videoTracks?.forEach(track => {
            track.stop(); // This is crucial - it actually turns off the camera
        });

        // Clear the local stream's video tracks
        videoTracks?.forEach(track => {
            localStream?.removeTrack(track);
        });

        // Clear the video source
        if (localVideoRef.current) {
            localVideoRef.current.srcObject = null;
        }

        //  videoMute()

        localStream?.getTracks().forEach(track => track.stop());
        setLocalStream(null);
    }


    useEffect(() => {
        socket.on('vc_connected', (data) => {
            // console.log('connecttttt')
        });

        socket.on('disconnect', () => {

        });

        // Add the event listener
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup on component unmount
        return () => {
            socket.off('vc_connected');
            socket.off('disconnect');

            window.removeEventListener('beforeunload', handleBeforeUnload);
            handleBeforeUnload();
        };
    }, [location.search]);

    return (
        <>
            {hasJoined ?
                <Conversation
                    localVideoRef={localVideoRef}
                    localStream={localStream}
                    setLocalStream={setLocalStream}
                    peers={peers}
                    activePopupIndex={activePopupIndex}
                    setActivePopupIndex={setActivePopupIndex}
                    setPeers={setPeers}
                    username={username}
                    setUsername={setUsername}
                    socket={socket}
                    room_id={room_id}
                    hasJoined={hasJoined}
                    setHasJoined={setHasJoined}
                    isVideoMuted={isVideoMuted}
                    isAudioMuted={isAudioMuted}
                    setIsVideoMuted={setIsVideoMuted}
                    setIsAudioMuted={setIsAudioMuted}
                    messageList={messageList}
                    setMessageList={setMessageList}
                    setScreenShare={setScreenShare}
                    screenShare={screenShare}
                    setScreenRecording={setScreenRecording}
                    screenRecording={screenRecording}
                    handRaise={handRaise}
                    setHandRaise={setHandRaise}
                    inMeetingNotification={inMeetingNotification}
                    setInMeetingNotification={setInMeetingNotification}
                    setIsHost={setIsHost}
                    isHost={isHost}
                    isOwner={isOwner}
                    setIsOwner={setIsOwner}
                    screenStream={screenStream}
                    setScreenStream={setScreenStream}
                    screenPeers={screenPeers}
                    setScreenPeers={setScreenPeers}
                    screenPeerRef={screenPeerRef}
                    currentScreenShareState={currentScreenShareState}
                    peersRef={peersRef}
                    whiteBoard={whiteBoard}
                    setWhiteBoard={setWhiteBoard}
                    setPin={setPin}
                    pin={pin}
                />
                :
                <Initial
                    peersRef={peersRef}
                    currentScreenShareState={currentScreenShareState}
                    localVideoRef={localVideoRef}
                    localStream={localStream}
                    setLocalStream={setLocalStream}
                    peers={peers}
                    setPeers={setPeers}
                    username={username}
                    setUsername={setUsername}
                    socket={socket}
                    room_id={room_id}
                    hasJoined={hasJoined}
                    setHasJoined={setHasJoined}
                    isVideoMuted={isVideoMuted}
                    isAudioMuted={isAudioMuted}
                    setIsVideoMuted={setIsVideoMuted}
                    setIsAudioMuted={setIsAudioMuted}
                    messageList={messageList}
                    setMessageList={setMessageList}
                    setIsHost={setIsHost}
                    isHost={isHost}
                    isOwner={isOwner}
                    setIsOwner={setIsOwner}
                    screenStream={screenStream}
                    setScreenStream={setScreenStream}
                    screenPeers={screenPeers}
                    setScreenPeers={setScreenPeers}
                    screenPeerRef={screenPeerRef}
                    whiteBoard={whiteBoard}
                    setWhiteBoard={setWhiteBoard}
                    setPin={setPin}
                    pin={pin}
                />
            }
        </>
    );
}

export default Socket;









// import React, { useState, useEffect, useContext, useRef } from 'react';
// import io from 'socket.io-client';
// import { useLocation, useSearchParams } from 'react-router-dom';
// import AppContext from '../AppContext';
// import Initial from './Initial'
// import Conversation from './Conversation'

// function Socket() {
//     const [socket, setSocket] = useState(null);
//     const currentScreenShareState = useRef({
//         stream: null,
//         isSharing: false,
//         previousVideoState: false
//     });
//     let { user, accessToken } = useContext(AppContext);

//     const [isHost, setIsHost] = useState(false);
//     const [isOwner, setIsOwner] = useState(false);
//     const [pin, setPin] = useState({
//         type: "",
//         peer_id: null,
//         pinned: false,
//     });
//     const [activePopupIndex, setActivePopupIndex] = useState(null);
//     const [messageList, setMessageList] = useState([]);
//     const [inMeetingNotification, setInMeetingNotification] = useState('');
//     const location = useLocation();
//     const localVideoRef = useRef(null);
//     const [localStream, setLocalStream] = useState(null);
//     const [whiteBoard, setWhiteBoard] = useState(false);
//     const [isVideoMuted, setIsVideoMuted] = useState(false);
//     const [screenShare, setScreenShare] = useState(false);
//     const [screenRecording, setScreenRecording] = useState(false);
//     const [handRaise, setHandRaise] = useState(false);
//     const [isAudioMuted, setIsAudioMuted] = useState(false);
//     const [peers, setPeers] = useState(null);
//     const peersRef = useRef(null);
//     const [hasJoined, setHasJoined] = useState(false);
//     const [username, setUsername] = useState(user?.username ? user?.username : "");
//     const [searchParams] = useSearchParams();
//     const room_id = searchParams.get("room_id");
//     const [screenStream, setScreenStream] = useState(null);
//     const [screenPeers, setScreenPeers] = useState([]);
//     const screenPeerRef = useRef(null);

//     // Create socket connection in useEffect
//     useEffect(() => {
//         const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}/vc`, {
//             reconnection: true,
//             reconnectionAttempts: 5,
//             reconnectionDelay: 1000,
//         });

//         newSocket.on('vc_connected', (data) => {
//             console.log('Socket connected');
//         });

//         newSocket.on('disconnect', () => {
//             console.log('Socket disconnected');
//         });

//         setSocket(newSocket);

//         // Cleanup function to disconnect socket when component unmounts
//         return () => {
//             if (newSocket) {
//                 newSocket.disconnect();
//             }
//         };
//     }, []); // Empty dependency array means this runs once on mount

//     useEffect(() => {
//         peersRef.current = peers;
//     }, [peers]);

//     const handleBeforeUnload = () => {
//         const videoTracks = localStream?.getVideoTracks();
//         videoTracks?.forEach(track => {
//             track.stop();
//         });
//         videoTracks?.forEach(track => {
//             localStream?.removeTrack(track);
//         });
//         if (localVideoRef.current) {
//             localVideoRef.current.srcObject = null;
//         }
//         localStream?.getTracks().forEach(track => track.stop());
//         setLocalStream(null);
//     };

//     useEffect(() => {
//         window.addEventListener('beforeunload', handleBeforeUnload);
//         return () => {
//             window.removeEventListener('beforeunload', handleBeforeUnload);
//             handleBeforeUnload();
//         };
//     }, [location.search, localStream]);

//     useEffect(() => {
//         const screenSharingPeers = peers?.filter(item => item.is_screensharing === true);
//         const whiteBoardPeers = peers?.filter(item => item.sharing_whiteboard === true);

//         if (!pin.pinned && (screenSharingPeers?.length > 0 || whiteBoardPeers?.length > 0 || whiteBoard || screenShare)) {
//             setPin((prevPin) => ({
//                 ...prevPin,
//                 type: whiteBoard ? "whiteBoard" : "screenShare",
//                 pinned: true,
//             }));
//         }

//         if (pin.pinned && screenSharingPeers?.length === 0 && whiteBoardPeers?.length === 0 && !whiteBoard && !screenShare) {
//             setPin((prevPin) => ({
//                 ...prevPin,
//                 type: "",
//                 pinned: false,
//             }));
//         }
//     }, [peers, whiteBoard, screenShare, pin.pinned]);

//     // Don't render anything until socket is initialized
//     if (!socket) return null;

//     return (
//         <>
//             {hasJoined ? (
//                 <Conversation
//                     socket={socket}
//                     localVideoRef={localVideoRef}
//                     localStream={localStream}
//                     setLocalStream={setLocalStream}
//                     peers={peers}
//                     activePopupIndex={activePopupIndex}
//                     setActivePopupIndex={setActivePopupIndex}
//                     setPeers={setPeers}
//                     username={username}
//                     setUsername={setUsername}
//                     room_id={room_id}
//                     hasJoined={hasJoined}
//                     setHasJoined={setHasJoined}
//                     isVideoMuted={isVideoMuted}
//                     isAudioMuted={isAudioMuted}
//                     setIsVideoMuted={setIsVideoMuted}
//                     setIsAudioMuted={setIsAudioMuted}
//                     messageList={messageList}
//                     setMessageList={setMessageList}
//                     setScreenShare={setScreenShare}
//                     screenShare={screenShare}
//                     setScreenRecording={setScreenRecording}
//                     screenRecording={screenRecording}
//                     handRaise={handRaise}
//                     setHandRaise={setHandRaise}
//                     inMeetingNotification={inMeetingNotification}
//                     setInMeetingNotification={setInMeetingNotification}
//                     setIsHost={setIsHost}
//                     isHost={isHost}
//                     isOwner={isOwner}
//                     setIsOwner={setIsOwner}
//                     screenStream={screenStream}
//                     setScreenStream={setScreenStream}
//                     screenPeers={screenPeers}
//                     setScreenPeers={setScreenPeers}
//                     screenPeerRef={screenPeerRef}
//                     currentScreenShareState={currentScreenShareState}
//                     peersRef={peersRef}
//                     whiteBoard={whiteBoard}
//                     setWhiteBoard={setWhiteBoard}
//                     setPin={setPin}
//                     pin={pin}
//                 />
//             ) : (
//                 <Initial
//                     socket={socket}
//                     peersRef={peersRef}
//                     currentScreenShareState={currentScreenShareState}
//                     localVideoRef={localVideoRef}
//                     localStream={localStream}
//                     setLocalStream={setLocalStream}
//                     peers={peers}
//                     setPeers={setPeers}
//                     username={username}
//                     setUsername={setUsername}
//                     room_id={room_id}
//                     hasJoined={hasJoined}
//                     setHasJoined={setHasJoined}
//                     isVideoMuted={isVideoMuted}
//                     isAudioMuted={isAudioMuted}
//                     setIsVideoMuted={setIsVideoMuted}
//                     setIsAudioMuted={setIsAudioMuted}
//                     messageList={messageList}
//                     setMessageList={setMessageList}
//                     setIsHost={setIsHost}
//                     isHost={isHost}
//                     isOwner={isOwner}
//                     setIsOwner={setIsOwner}
//                     screenStream={screenStream}
//                     setScreenStream={setScreenStream}
//                     screenPeers={screenPeers}
//                     setScreenPeers={setScreenPeers}
//                     screenPeerRef={screenPeerRef}
//                     whiteBoard={whiteBoard}
//                     setWhiteBoard={setWhiteBoard}
//                     setPin={setPin}
//                     pin={pin}
//                 />
//             )}
//         </>
//     );
// }

// export default Socket;