import React, { useState, useRef, useEffect } from 'react';
import getStroke from 'perfect-freehand';

const WhiteboardCanvas = (props) => {
    const { username, socket, room_id } = props

    const [currentPoints, setCurrentPoints] = useState([]);
    const [completedPaths, setCompletedPaths] = useState([]);
    const [undonePaths, setUndonePaths] = useState([]); // Track undone paths
    const [isDrawing, setIsDrawing] = useState(false);
    const canvasRef = useRef(null);

    const options = {
        size: 8,
        thinning: 0.5,
        smoothing: 0.5,
        streamline: 0.5,
    };

    const clearBoard = () => {
        setCompletedPaths([]);
        setCurrentPoints([]);
        setUndonePaths([]);
    }

    const undoBoard = () => {
        if (completedPaths.length > 0) {
            const lastPath = completedPaths[completedPaths.length - 1];
            setCompletedPaths(paths => paths.slice(0, -1));
            setUndonePaths(paths => [lastPath, ...paths]);
        }
    }

    const redoBoard = () => {
        if (undonePaths.length > 0) {
            const [pathToRedo, ...remainingUndone] = undonePaths;
            setUndonePaths(remainingUndone);
            setCompletedPaths(paths => [...paths, pathToRedo]);
        }
    }

    // Handle undo and redo keyboard shortcuts
    const handleKeyboardShortcuts = (e) => {
        e.preventDefault();

        // Undo: Ctrl/Cmd + Z
        if ((e.ctrlKey || e.metaKey) && !e.shiftKey && e.key === 'z') {
            const lastPath = completedPaths[completedPaths.length - 1];

            socket.emit('send_whiteboard_undo', {
                room_id: room_id,
                username: username,
                socket_id: socket.id,
                newPaths: completedPaths.slice(0, -1),
                undonePath: lastPath
            });

            undoBoard()
        }
        // Redo: Ctrl/Cmd + Shift + Z
        else if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === 'z') {
            const [pathToRedo, ...remainingUndone] = undonePaths;

            socket.emit('send_whiteboard_redo', {
                room_id: room_id,
                username: username,
                socket_id: socket.id,
                newCompletedPaths: [...completedPaths, pathToRedo],
                newUndonePaths: remainingUndone
            });

            redoBoard()
        }
    };

    // Add keyboard event listener
    useEffect(() => {
        window.addEventListener('keydown', handleKeyboardShortcuts);

        return () => {
            window.removeEventListener('keydown', handleKeyboardShortcuts);
        };
    }, [completedPaths, undonePaths]);

    useEffect(() => {
        const handleReceivedPath = (data) => {
            setCompletedPaths(paths => [...paths, data.path]);
        };

        const handleClear = () => {
            clearBoard();
        };

        const handleUndo = (data) => {
            setCompletedPaths(data.newPaths);
            setUndonePaths(paths => [data.undonePath, ...paths]);
        };

        const handleRedo = (data) => {
            setCompletedPaths(data.newCompletedPaths);
            setUndonePaths(data.newUndonePaths);
        };

        socket.on('received_request_paths', (data) => {
            socket.emit('send_requested_paths', {
                room_id: room_id,
                username: username,
                requester_socket_id: data.requester_socket_id,
                white_board_host_socket_id: socket.id,
                completedPaths: completedPaths
            });
        });

        socket.on('received_completed_paths', (data) => {
            setCompletedPaths(data.completedPaths);
        });

        // Add listeners
        socket.on('received_whiteboard_path', handleReceivedPath);
        socket.on('received_whiteboard_clear', handleClear);
        socket.on('received_whiteboard_undo', handleUndo);
        socket.on('received_whiteboard_redo', handleRedo);

        // Cleanup listeners
        return () => {
            socket.off('received_whiteboard_path', handleReceivedPath);
            socket.off('received_whiteboard_clear', handleClear);
            socket.off('received_whiteboard_undo', handleUndo);
            socket.off('received_whiteboard_redo', handleRedo);
        };
    }, [completedPaths]);

    const getSvgPathFromStroke = (stroke) => {
        if (!stroke.length) return '';

        const d = stroke.reduce((acc, [x0, y0], i, arr) => {
            const [x1, y1] = arr[(i + 1) % arr.length];
            acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
            return acc;
        }, ['M', ...stroke[0], 'Q']);

        return d.join(' ');
    };

    const handlePointerDown = (e) => {
        e.preventDefault();
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setCurrentPoints([[x, y]]);
        setIsDrawing(true);
        // Clear undone paths when starting a new drawing
        setUndonePaths([]);
    };

    const handlePointerMove = (e) => {
        e.preventDefault();
        if (!isDrawing) return;
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setCurrentPoints(points => [...points, [x, y]]);
    };

    const handlePointerUp = (e) => {
        e.preventDefault();
        setIsDrawing(false);
        if (currentPoints.length > 0) {
            const stroke = getStroke(currentPoints, options);
            const pathData = getSvgPathFromStroke(stroke);


            socket.emit('send_whiteboard_path', {
                room_id: room_id,
                username: username,
                socket_id: socket.id,
                path: pathData
            })

            setCompletedPaths(paths => [...paths, pathData]);
            setCurrentPoints([]);
            // Clear undone paths when completing a new stroke
            setUndonePaths([]);
        }
    };

    // Get current stroke path
    const currentStroke = getStroke(currentPoints, options);
    const currentPathData = getSvgPathFromStroke(currentStroke);

    return (
        <div id='vc_participants_massive_canvas'>
            <div className="vc_participants_ite_name">
                {username}
            </div>
            <div id='vc_white_board_text'>
                White Board
            </div>

            <div style={{
                width: "100%",
                height: "100%",
                overflowX: "scroll",
                overflowY: "scroll",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative"
            }}>
                <svg
                    ref={canvasRef}
                    style={{
                        position: 'absolute',
                        top: "0%",
                        left: "0%",
                        // transform: "translate(0%,-50%)",
                        width: '5000px',
                        height: '5000px',
                        touchAction: 'none',
                        cursor: 'crosshair'
                    }}
                    onPointerDown={handlePointerDown}
                    onPointerMove={handlePointerMove}
                    onPointerUp={handlePointerUp}
                    onPointerLeave={handlePointerUp}
                >
                    {completedPaths.map((pathData, index) => (
                        <path
                            key={index}
                            d={pathData}
                            fill="black"
                            stroke="black"
                            strokeWidth="1"
                        />
                    ))}
                    {currentPoints.length > 0 && (
                        <path
                            d={currentPathData}
                            fill="black"
                            stroke="black"
                            strokeWidth="1"
                        />
                    )}
                </svg>
            </div>

            <div style={{
                position: 'absolute',
                bottom: '8px',
                right: '8px',
                display: 'flex',
                gap: '8px',
                zIndex: 100
            }}>
                <button
                    style={{
                        padding: '8px 16px',
                        backgroundColor: '#e2e2e2',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        opacity: completedPaths.length > 0 ? 1 : 0.5,
                    }}
                    onClick={() => {
                        const lastPath = completedPaths[completedPaths.length - 1];

                        socket.emit('send_whiteboard_undo', {
                            room_id: room_id,
                            username: username,
                            socket_id: socket.id,
                            newPaths: completedPaths.slice(0, -1),
                            undonePath: lastPath
                        });

                        undoBoard()
                    }}
                >
                    Undo
                </button>
                <button
                    style={{
                        padding: '8px 16px',
                        backgroundColor: '#e2e2e2',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        opacity: undonePaths.length > 0 ? 1 : 0.5,
                    }}
                    onClick={() => {
                        const [pathToRedo, ...remainingUndone] = undonePaths;

                        socket.emit('send_whiteboard_redo', {
                            room_id: room_id,
                            username: username,
                            socket_id: socket.id,
                            newCompletedPaths: [...completedPaths, pathToRedo],
                            newUndonePaths: remainingUndone
                        });

                        redoBoard()
                    }}
                >
                    Redo
                </button>
                <button
                    style={{
                        padding: '8px 16px',
                        backgroundColor: '#e2e2e2',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        socket.emit('send_whiteboard_clear', {
                            room_id: room_id,
                            username: username,
                            socket_id: socket.id,
                        })

                        clearBoard()
                    }}
                >
                    Clear
                </button>
            </div>
        </div>
    );
};

export default WhiteboardCanvas;