import React, { useEffect, useState } from 'react'
import NavigationOptions from './ReUsables/NavigationOptions'
import ChatsOptions from './ReUsables/ChatsOptions'
import ChatHistory from './ReUsables/ChatHistory'
import { useSearchParams } from 'react-router-dom'
import io from 'socket.io-client';


const ChatGroup = () => {
  // let socket = []
  const [socket, setSocket] = useState(
    io(`${process.env.REACT_APP_SOCKET_URL}/im2`, {
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    })
  )
  const [searchParams, setSearchParams] = useSearchParams();
  const chatGroup_id = searchParams.get("_id")
  const [lastMessage, setLastMessage] = useState(null)

  useEffect(() => {
    // const soc = io(`${process.env.REACT_APP_SOCKET_URL}/im2`, {
    //   reconnection: true,
    //   reconnectionAttempts: 5,
    //   reconnectionDelay: 1000,
    // });

    // setSocket(soc)

    socket?.on('receive_message', (data) => {
      setLastMessage(data)
    });

    socket?.emit('join_chats', {
      accessToken: localStorage.getItem('accessToken')
    }, (response) => {
      // console.log(response)
    })


    // socket.on('vc_connected', (data) => {
    //   // console.log('connecttttt')
    // });

    // socket.on('disconnect', () => {

    // });

    // Add the event listener
    // window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup on component unmount
    return () => {
      socket?.off('vc_connected');
      socket?.off('disconnect');

      // window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <NavigationOptions />
      <ChatsOptions
        socket={socket}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
      {chatGroup_id && chatGroup_id?.trim() !== "" &&
        <ChatHistory lastMessage={lastMessage} socket={socket} chatGroup_id={chatGroup_id} />
      }
    </>
  )
}

export default ChatGroup