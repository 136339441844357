import React, { useState, useEffect, useMemo } from "react";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";

export default function SearchBar({
  searchInput,
  setSearchInput,
  isLoading,
  setIsLoading,
  setRooms,
  rooms,
}) {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [roomName, setRoomName] = useState("");
  const [filteredRooms, setFilteredRooms] = useState([]);

  const maxLength = 30;
  const isOverLimit = roomName.length > maxLength;

  const onChangeHandler = (event) => {
    const value = event.target.value;
    setSearchInput(value);

    const filtered = rooms.filter((room) =>
      room.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredRooms(filtered);
  };

  const debouncedOnChangeHandler = useMemo(
    () => debounce(onChangeHandler, 300),
    [rooms]
  );

  useEffect(() => {
    return () => {
      debouncedOnChangeHandler.cancel();
    };
  }, [debouncedOnChangeHandler]);

  const handleCreateRoom = async () => {
    if (!roomName.trim()) {
      alert(t("Room name cannot be empty"));
      return;
    }

    if (roomName.length > maxLength) {
      alert(t("Room name too long. Max 30 characters allowed"));
      return;
    }

    const newRoom = {
      name: roomName,
      created_at: new Date().toISOString(),
    };

    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      console.error("Access token not found in localStorage");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_REST_URL}/vc/create-room`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            name: roomName,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(t("Failed to create room"));
      }

      const createdRoom = await response.json();

      setRooms(createdRoom.userRooms.reverse());
      setIsModalOpen(false);
      setRoomName("");
    } catch (error) {
      console.error("Error creating room:", error);
      alert(t("Failed to create room. Please try again."));
    }
  };

  const buttonStyle = {
    padding: "10px 20px",
    background: "rgb(125, 161, 14)",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "background 0.3s ease, transform 0.2s ease",
  };

  const hoverStyle = {
    background: "rgb(100, 140, 10)",
    transform: "scale(1.05)",
  };

  const [isHovered, setIsHovered] = React.useState(false);

  const closeButtonStyle = {
    padding: "10px 50px",
    background: "#fff",
    color: "#000",
    border: "1px solid #ddd",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "all 0.3s ease",
  };

  const closeHoverStyle = {
    background: "#f0f0f0",
    transform: "scale(1.05)",
  };

  const [isCloseHovered, setIsCloseHovered] = React.useState(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "900px",
          gap: "25px",
          justifyContent: "flex-end",
        }}
      >
        <button
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
            borderRadius: "50px",
            width: "60px",
            height: "60px",
            fontSize: "40px",
            color: "#fff",
            background: "#7da10e",
            cursor: "pointer",
            transition: "transform 0.2s, background 0.3s",
            outline: "none",
          }}
          onMouseEnter={(e) => {
            e.target.style.background = "#7da10e";
            e.target.style.transform = "scale(1.05)";
          }}
          onMouseLeave={(e) => {
            e.target.style.background = "#7da10e";
            e.target.style.transform = "scale(1)";
          }}
          onClick={() => {
            setIsModalOpen(true);
            const dimScreen = document.getElementById("dimscreen");
            if (dimScreen) {
              dimScreen.classList.add("active");
            }
          }}
        >
          {t("+")}
        </button>
      </div>

      {isLoading ? (
        <div>{t("Loading...")}</div>
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            gap: "20px",
            marginTop: "20px",
            width: "100%",
            maxWidth: "900px",
          }}
        ></div>
      )}

      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: "#fff",
              padding: "4px",
              borderRadius: "8px",
              width: "24%",
              display: "flex",
              flexDirection: "column",
              height: "200px",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <h2
              style={{
                display: "flex",
                // background: "#edffee",
                width: "90%",
                padding: "20px",
                justifyContent: "center",
                margin: "0",
                borderRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              }}
            >
              {t("Create a New Room")}
            </h2>
            <input
              type="text"
              value={roomName}
              onChange={(e) => setRoomName(e.target.value)}
              placeholder={t("Enter a room name")}
              style={{
                padding: "15px",
                marginBottom: "15px",
                borderRadius: "8px",
                border: isOverLimit ? "1px solid red" : "1px solid #ccc",
                width: "90%",
                outline: "none",
              }}
            />
            {isOverLimit && (
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  right: "650px",
                  top: "422px",
                }}
              >
                ⚠️ {t("Max 30 characters")}
              </span>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <button
                onClick={() => setIsModalOpen(false)}
                style={
                  isCloseHovered
                    ? { ...closeButtonStyle, ...closeHoverStyle }
                    : closeButtonStyle
                }
                onMouseEnter={() => setIsCloseHovered(true)}
                onMouseLeave={() => setIsCloseHovered(false)}
              >
                {t("Close")}
              </button>
              <button
                onClick={handleCreateRoom}
                style={
                  isHovered ? { ...buttonStyle, ...hoverStyle } : buttonStyle
                }
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {t("Create Room")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
