import '../style.css'
import { IoCreateOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { IoPerson } from "react-icons/io5";
import { IoPeopleSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';
import AppContext from '../../AppContext';
import { useContext } from 'react';


const ChatsOptions = (props) => {
    const { searchParams, setSearchParams } = props
    const {
        userChats,
        setUserCharts,
        user
    } = useContext(AppContext)

    const createChatGroup = async (text) => {
        const accessToken = localStorage.getItem('accessToken')

        console.log(accessToken)

        const response = await fetch(`${process.env.REACT_APP_REST_URL}/im2/create-chatgroup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                emails: text
            })
        });

        const data = await response.json()
        setUserCharts(data.chatGroups)
    }

    return (
        <div id='chat_options_master'>
            <div id='chat_options_maste_head'>
                <span id='chat_options_maste_hea_title'>Chats</span>
                <div onClick={() => {
                    const text = window.prompt("Email of Users (comma separated)", "example@gmail.com");
                    if (text) {
                        createChatGroup(text)
                    }
                }} id='chat_options_maste_hea_ico_container'>
                    <IoCreateOutline id='chat_options_maste_hea_icon' />
                </div>
            </div>
            <div id='chat_options_maste_search_m'>
                <IoIosSearch id='chat_options_maste_search_c_icon' />
                <input spellCheck='false' placeholder='Search' id='chat_options_maste_search_c_input' />
            </div>
            <div id='chat_options_chats_master'>
                {userChats?.map((data, index) => {
                    return (
                        <div key={index}
                            onClick={() => {
                                setSearchParams({ _id: data._id });
                            }}
                            className='chat_options_c_container_master'>
                            <div className='chat_options_c_container_child_img_container'>
                                <img className='chat_options_c_container_child_img_icon' src='https://images.unsplash.com/photo-1736148912326-aeeda15df88f?q=80&w=3164&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' />
                            </div>
                            <div className='chat_options_c_container_child_info_master'>
                                <div className='chat_options_c_container_child_info_child_head'>
                                    <span className='chat_options_c_container_child_info_child_h_name'>
                                        {data.users.length > 2 ?
                                            <>{data?.users.map(user => user.username).join(', ')}</>
                                            :
                                            <>{data.users.filter(item => item.email !== user.email)[0].username}</>
                                        }
                                    </span>
                                    <span className='chat_options_c_container_child_info_child_h_time'>10:24 AM</span>
                                </div>
                                <div className='chat_options_c_container_child_info_child_body'>Hello</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ChatsOptions