import React, { useState, useEffect, useContext, useRef } from 'react';
import io from 'socket.io-client';
import Peer from 'simple-peer';
import { IoMdMic, IoMdMicOff } from "react-icons/io";
import { HiVideoCamera, HiVideoCameraSlash } from "react-icons/hi2";
import { useLocation, useSearchParams } from 'react-router-dom';
import AppContext from '../AppContext';
import VideoConference from './Video Metting Screen/VideoConference';
import { useNavigationType } from 'react-router-dom';



function Conversation(props) {
    const navigationType = useNavigationType();
    let { user, accessToken } = useContext(AppContext);
    const location = useLocation();
    const isFirstRender = useRef(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const screenShareRef = useRef(null);
    const {
        room_id,
        socket,
        hasJoined,
        setHasJoined,
        username,
        currentScreenShareState,
        setUsername,
        isVideoMuted,
        isAudioMuted,
        setIsVideoMuted,
        setIsAudioMuted,
        peers,
        setPeers,
        localVideoRef,
        localStream,
        setLocalStream,
        messageList,
        peersRef,
        setMessageList,
        pin,
        setPin,
        setScreenShare,
        screenShare,
        screenRecording,
        setScreenRecording,
        handRaise,
        setHandRaise,
        whiteBoard,
        setWhiteBoard,
        inMeetingNotification,
        activePopupIndex,
        setActivePopupIndex,
        setInMeetingNotification,
        setIsHost,
        isHost,
        isOwner,
        setIsOwner,
        screenStream,
        setScreenStream,
        screenPeers,
        setScreenPeers,
        screenPeerRef,
    } = props;
    const [userMessage, setUserMessage] = useState('')
    const [previouslyVideoMuted, setPreviouslyVideoMuted] = useState(false)
    const notificationTimeoutRef = useRef(null);

    const [handRaiseList, setHandRaiseList] = useState([])


    const toggleHandRaise = () => {
        setHandRaise(!handRaise)
    };

    const toggleScreenRecording = () => {
        setScreenRecording(!screenRecording)
    };

    const audioMute = async () => {
        const audioTracks = localStream.getAudioTracks();

        // Update UI to show audio is muted
        setIsAudioMuted(true);
        updatePeerTracks(true, isVideoMuted);

        socket.emit('mute_audio', {
            room_id: room_id,
            username: username,
            socket_id: socket.id,
        })

        // Stop all existing audio tracks
        audioTracks.forEach(track => {
            track.stop(); // This is crucial - it actually turns off the microphone
        });

        // Clear the local stream's audio tracks
        audioTracks.forEach(track => {
            localStream.removeTrack(track);
        });
    }

    const videoMute = async () => {
        const videoTracks = localStream.getVideoTracks();

        // Update UI to show video is muted
        setIsVideoMuted(true);
        updatePeerTracks(isAudioMuted, true);

        socket.emit('mute_video', {
            room_id: room_id,
            username: username,
            socket_id: socket.id,
        })

        // Stop all existing video tracks
        videoTracks.forEach(track => {
            track.stop(); // This is crucial - it actually turns off the camera
        });

        // Clear the local stream's video tracks
        videoTracks.forEach(track => {
            localStream.removeTrack(track);
        });

        // Clear the video source
        if (localVideoRef.current) {
            localVideoRef.current.srcObject = null;
        }
    }


    const sendMessage = async (e) => {
        e.preventDefault();

        const item = userMessage.trim();
        if (!item) return; // Avoid sending empty messages

        // Reset the user input
        setUserMessage('');

        // Get the current time in Pakistan Standard Time
        const timeFormatter = new Intl.DateTimeFormat('en-PK', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone: 'Asia/Karachi'
        });
        const currentTime = timeFormatter.format(new Date());

        // Create the payload
        const messagePayload = {
            time: currentTime,
            message: item,
            username: username
        };

        // Update the local message list
        setMessageList((prevMessages) => {
            const updatedMessages = [...prevMessages, messagePayload];

            // Scroll after messages have been updated
            setTimeout(() => {
                const objDiv = document.getElementById("chat_and_participants_body_msg_wrapper");
                if (objDiv && objDiv.scrollHeight) {
                    objDiv.scrollTop = objDiv.scrollHeight;
                }
            }, 0);

            return updatedMessages;
        });

        // Emit the message to the server if room_id exists
        if (room_id) {
            socket.emit('send_message', {
                room_id: room_id,
                message: item,
                username: username,
                accessToken: accessToken
            }, (response) => {
                // Handle response if needed
            });
        }
    };

    const muteAllVideo = () => {
        socket.emit('mute_all_video', {
            room_id: room_id,
            username: username,
            socket_id: user.peer_id,
            isOwner: isOwner,
            isHost: isHost
        })
        setActivePopupIndex(null)
    }

    const muteAllMic = () => {
        socket.emit('mute_all_audio', {
            room_id: room_id,
            username: username,
            socket_id: user.peer_id,
            isOwner: isOwner,
            isHost: isHost
        })
        setActivePopupIndex(null)
    }

    const muteUserMic = (user) => {
        socket.emit('mute_target_audio', {
            room_id: room_id,
            username: username,
            socket_id: user.peer_id,
        })
        setActivePopupIndex(null)
    }

    const muteUserVideo = (user) => {
        socket.emit('mute_target_video', {
            room_id: room_id,
            username: username,
            socket_id: user.peer_id,
        })
        setActivePopupIndex(null)
    }

    const endMeetingForAll = () => {
        socket.emit('end_meeting_for_all', {
            room_id: room_id,
            username: username
        }, (response) => {
            handleLeaveRoom()
        });
    }

    const kickUser = (user) => {
        socket.emit('kick_user', {
            room_id: room_id,
            peer_id: user.peer_id
        }, (response) => {
            setActivePopupIndex(null)
        });
    }

    const promoteToHost = (user) => {
        socket.emit('promote_to_host', {
            room_id: room_id,
            peer_id: user.peer_id
        }, (response) => {
            if (notificationTimeoutRef.current) {
                clearTimeout(notificationTimeoutRef.current);
            }

            setPeers((prevPeers) => {
                const newPeers = prevPeers.map(peer =>
                    peer.peer_id === response.peer_id
                        ? { ...peer, is_host: true }
                        : peer
                );

                return newPeers;
            });

            // Set notification with the username who left
            setInMeetingNotification(`${user?.username || 'A participant'} is now a host`);

            setActivePopupIndex(null)

            // Set a new timeout to clear the notification
            notificationTimeoutRef.current = setTimeout(() => {
                setInMeetingNotification('');
                // Clear the ref after timeout
                notificationTimeoutRef.current = null;
            }, 3000);
        });
    }

    const demoteToViewer = (user) => {
        socket.emit('demote_to_viewer', {
            room_id: room_id,
            peer_id: user.peer_id
        }, (response) => {
            if (notificationTimeoutRef.current) {
                clearTimeout(notificationTimeoutRef.current);
            }

            setPeers((prevPeers) => {
                const newPeers = prevPeers.map(peer =>
                    peer.peer_id === response.peer_id
                        ? { ...peer, is_host: false }
                        : peer
                );

                return newPeers;
            });

            // Set notification with the username who left
            setInMeetingNotification(`${user?.username || 'A participant'} has been removed as host`);

            setActivePopupIndex(null)

            // Set a new timeout to clear the notification
            notificationTimeoutRef.current = setTimeout(() => {
                setInMeetingNotification('');
                // Clear the ref after timeout
                notificationTimeoutRef.current = null;
            }, 3000);
        });
    }

    // Function to handle leaving the room
    const handleLeaveRoom = () => {
        // Destroy all peer connections
        peers.forEach(({ peer }) => {
            if (peer) {
                try {
                    peer.destroy();
                } catch (error) {
                    console.error('Error destroying peer:', error);
                }
            }
        });

        if (room_id) {
            socket.emit('leave_room', {
                room_id: room_id,
                username: username
            }, (response) => {
                // Log the callback acknowledgement
                setPeers([]); // Clear peers
                setHasJoined(false)
            });
        }

        const videoTracks = localStream.getVideoTracks();

        // Stop all existing video tracks
        videoTracks.forEach(track => {
            track.stop(); // This is crucial - it actually turns off the camera
        });

        // Clear the local stream's video tracks
        videoTracks.forEach(track => {
            localStream.removeTrack(track);
        });

        // Clear the video source
        if (localVideoRef.current) {
            localVideoRef.current.srcObject = null;
        }


        console.log('left')

        //  videoMute()

        localStream.getTracks().forEach(track => track.stop());
        setLocalStream(null);
    };

    const handleHandRaise = () => {
        if (handRaise) {
            setHandRaise(false)

            const updatedHandRaises = handRaiseList.filter(h => h.socket_id !== socket.id);

            setHandRaiseList(updatedHandRaises);

            socket.emit('lower_hand_raise', {
                room_id: room_id,
                username: username,
                accessToken: accessToken,
            }, (response) => {

            });
        }
        else {
            setHandRaise(true)

            // Instead of mutating the existing array, create a new one
            const updatedHandRaises = [
                ...handRaiseList,
                {
                    username: username,
                    socket_id: socket.id
                }
            ];

            setHandRaiseList(updatedHandRaises);

            socket.emit('hand_raise', {
                room_id: room_id,
                username: username,
                accessToken: accessToken,
            }, (response) => {

            });
        }
    }

    // Get local media stream
    const getMediaStream = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: !isVideoMuted,
                audio: !isAudioMuted
            });
            setLocalStream(stream);

            if (localVideoRef.current) {
                localVideoRef.current.srcObject = stream;
            }

            // Signal readiness to join room
            socket.emit('join_room', {
                room_id,
                username,
                accessToken
            });
        } catch (error) {
            console.error('Error accessing media devices:', error);
        }
    };


    const toggleVideoMute = async () => {
        if (localStream && !screenShare) {
            const videoTracks = localStream.getVideoTracks();

            if (videoTracks.length > 0) {
                videoMute()
            } else {
                // If no tracks, try to re-enable video
                try {
                    // Enable the new track and update state
                    setIsVideoMuted(false);

                    socket.emit('unmute_video', {
                        room_id: room_id,
                        username: username,
                        socket_id: socket.id,
                    })

                    const newStream = await navigator.mediaDevices.getUserMedia({
                        video: true,
                        audio: false
                    });

                    const newVideoTrack = newStream.getVideoTracks()[0];

                    updatePeerTracks(isAudioMuted, false, newVideoTrack, true);

                    // Add the new track to the existing stream
                    localStream.addTrack(newVideoTrack);

                    // Update video source
                    if (localVideoRef.current) {
                        localVideoRef.current.srcObject = localStream;
                    }
                } catch (error) {
                    console.error('Error re-enabling video:', error);
                    alert('Could not re-enable video. Check camera permissions.');
                }
            }
        }
    };

    const toggleWhiteBoard = () => {
        setWhiteBoard(!whiteBoard);
        if (whiteBoard) {
            stopWhiteBoard()
        }
        else {
            startWhiteBoard()
        }
    };

    const stopWhiteBoard = async () => {
        socket.emit('stop_whiteboard', {
            room_id: room_id,
            username: username,
            socket_id: socket.id,
        })
    }

    const startWhiteBoard = async () => {
        socket.emit('start_whiteboard', {
            room_id: room_id,
            username: username,
            socket_id: socket.id,
        })
    }


    const toggleAudioMute = async () => {
        if (localStream) {
            const audioTracks = localStream.getAudioTracks();

            if (audioTracks.length > 0) {
                audioMute()
            } else {
                // If no tracks, try to re-enable audio
                try {
                    // Enable the new track and update state
                    setIsAudioMuted(false);

                    socket.emit('unmute_audio', {
                        room_id: room_id,
                        username: username,
                        socket_id: socket.id,
                    })

                    const newStream = await navigator.mediaDevices.getUserMedia({
                        video: false,
                        audio: true
                    });

                    const newAudioTrack = newStream.getAudioTracks()[0];

                    updatePeerTracks(false, isVideoMuted, newAudioTrack, true);

                    // Add the new track to the existing stream
                    localStream.addTrack(newAudioTrack);
                } catch (error) {
                    console.error('Error re-enabling audio:', error);
                    alert('Could not re-enable audio. Check microphone permissions.');
                }
            }
        }
    };

    const updatePeerTracks = (newAudioState, newVideoState, newTrack, replace) => {
        if (localStream) {
            localStream.getTracks().forEach(track => {
                if (track.kind === 'video') {
                    track.enabled = !newVideoState;
                }
                if (track.kind === 'audio') {
                    track.enabled = !newAudioState;
                }
            });

            if (replace && newTrack) {

                peers.forEach(({ peer }) => {
                    if (peer && peer.streams && peer.streams[0]) {
                        const tracks = peer.streams[0].getTracks();
                        const existingTrack = tracks.find(track => track.kind === newTrack.kind);

                        if (existingTrack) {
                            peer.replaceTrack(existingTrack, newTrack, peer.streams[0]);
                        }
                    }
                });
            }

            // Update tracks for all peers
            peers.forEach(({ peer }) => {
                if (peer && peer.streams && peer.streams[0]) {

                    peer.streams[0].getTracks().forEach(track => {
                        if (track.kind === 'video') {
                            track.enabled = !newVideoState;
                        }
                        if (track.kind === 'audio') {
                            track.enabled = !newAudioState;
                        }
                    });
                }
            });
        }
    };

    function addPeer(incomingSignal, new_user_socket_id, stream, isScreenSharing) {

        if (stream && !isScreenSharing) {
            stream.getTracks().forEach(track => {
                if (track.kind === 'video') {
                    track.enabled = !isVideoMuted;
                }
                if (track.kind === 'audio') {
                    track.enabled = !isAudioMuted;
                }
            });
        }

        const peer = new Peer({
            initiator: false,
            trickle: false,
            config: {
                iceServers: [
                    { urls: 'stun:stun.l.google.com:19302' },
                    { urls: 'stun:stun1.l.google.com:19302' }
                ]
            },
            offerOptions: {
                offerToReceiveAudio: true,
                offerToReceiveVideo: true
            },
            stream
        });

        peer.on('stream', stream => {

        })

        peer.on('signal', signal => {
            socket.emit('returning_signal', {
                new_user_socket_id: new_user_socket_id,
                signal: signal,
                videoMuted: isVideoMuted,
                audioMuted: isAudioMuted
            });
        });

        peer.on('error', (err) => {
            console.error('Peer connection error:', err);
            setPeers(currentPeers =>
                currentPeers.filter(p => p.peer_id !== new_user_socket_id)
            );
        });

        peer.signal(incomingSignal);

        return peer;
    }

    const stopScreenShare = async () => {
        try {

            setScreenStream(null);
            setScreenShare(false);
            setPreviouslyVideoMuted(false)

            // Stop all tracks in screen stream
            if (screenStream) {
                screenStream.getTracks().forEach(track => track.stop());
            }

            await new Promise((resolve) => {
                socket.emit('user_stopped_screenshare', {
                    room_id: room_id,
                    username: username,
                    socket_id: socket.id,
                }, () => {
                    resolve();
                });
            });

            // socket.emit('user_stopped_screenshare', {
            //     room_id: room_id,
            //     username: username,
            //     socket_id: socket.id,
            // });

            if (currentScreenShareState.current.wasVideoMuted) {
                videoMute()
            }
            else {
                // Enable the new track and update state
                setIsVideoMuted(false);

                socket.emit('unmute_video', {
                    room_id: room_id,
                    username: username,
                    socket_id: socket.id,
                })

                const newStream = await navigator.mediaDevices.getUserMedia({
                    video: true,
                    audio: false
                });

                const newVideoTrack = newStream.getVideoTracks()[0];

                if (localStream) {
                    const oldTrack = localStream.getVideoTracks()[0];
                    if (oldTrack) {
                        oldTrack.stop();
                        localStream.removeTrack(oldTrack);
                    }
                }

                // Add the new track to the existing stream
                localStream.addTrack(newVideoTrack);

                // Update video source
                if (localVideoRef.current) {
                    localVideoRef.current.srcObject = localStream;
                }

                localStream.getTracks().forEach(track => {
                    if (track.kind === 'video') {
                        track.enabled = true;
                    }
                    if (track.kind === 'audio') {
                        track.enabled = !isAudioMuted;
                    }
                });

                peersRef.current.forEach(({ peer }) => {
                    if (peer.streams[0]) {
                        const tracks = peer.streams[0].getTracks();
                        const videoTrack = tracks.find(track => track.kind === 'video');
                        if (videoTrack) {
                            peer.replaceTrack(currentScreenShareState.current.screenTrack, newVideoTrack, peer.streams[0]);
                        }
                    }
                });

                currentScreenShareState.current = {
                    stream: null,
                    isSharing: false,
                    screenTrack: null,
                    wasVideoMuted: false
                };
            }
        } catch (error) {
            console.error('Error stopping screen share:', error);
            setScreenShare(false);
        }
    };

    const createScreenSharePeer = (target_peer_id, stream) => {
        const peer = new Peer({
            initiator: true,
            trickle: false,
            config: {
                iceServers: [
                    { urls: 'stun:stun.l.google.com:19302' },
                    { urls: 'stun:stun1.l.google.com:19302' }
                ]
            },
            offerOptions: {
                offerToReceiveAudio: true,
                offerToReceiveVideo: true
            },
            stream
        });

        peer.on('signal', signal => {
            socket.emit('screen_share_signal', {
                room_id,
                to_peer_id: target_peer_id,
                from_peer_id: socket.id,
                signal,
                username
            });
        });

        return peer;
    };

    const startScreenShare = async () => {
        try {
            // Get screen sharing stream
            const stream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: true
            });

            // Capture current video mute state before starting screen share
            const wasVideoMuted = isVideoMuted;

            if (wasVideoMuted) {
                setIsVideoMuted(false);
                updatePeerTracks(isAudioMuted, false);
                socket.emit('unmute_video', {
                    room_id: room_id,
                    username: username,
                    socket_id: socket.id,
                });
            }

            // Update state
            setScreenShare(true);
            setScreenStream(stream);
            setPreviouslyVideoMuted(wasVideoMuted);

            const screenTrack = stream.getVideoTracks()[0];
            const originalVideoTrack = localStream.getVideoTracks()[0];

            if (!isVideoMuted && originalVideoTrack) {
                localStream.removeTrack(originalVideoTrack);
            }

            localStream.addTrack(screenTrack);

            currentScreenShareState.current = {
                stream: localStream,
                isSharing: true,
                screenTrack: screenTrack,
                wasVideoMuted: wasVideoMuted
            };

            // Update local video display
            if (localVideoRef.current) {
                localVideoRef.current.srcObject = localStream;
            }

            // Update peers with screen track
            peers.forEach(({ peer }) => {
                if (peer.streams[0]) {
                    const tracks = peer.streams[0].getTracks();
                    const videoTrack = tracks.find(track => track.kind === 'video');
                    if (videoTrack) {
                        peer.replaceTrack(videoTrack, screenTrack, peer.streams[0]);
                    }
                }
            });

            socket.emit('user_started_screenshare', {
                room_id: room_id,
                username: username,
                socket_id: socket.id,
            });

            screenTrack.onended = async () => {
                try {
                    await stopScreenShare();
                } catch (error) {
                    console.error('Error handling screen share end:', error);
                }
            };
        } catch (error) {
            console.error('Error starting screen share:', error);
            setScreenShare(false);
        }
    };

    const toggleScreenShare = () => {
        setScreenShare(!screenShare);
        if (screenShare) {
            stopScreenShare()
        }
        else {
            startScreenShare()
        }
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            console.log('left')

            console.log(screenStream)

            // Cleanup operations for browser reload/close
            if (screenStream && screenStream?.getTracks()) {
                screenStream?.getTracks()?.forEach(track => track?.stop());
            }
            if (screenPeerRef.current) {
                screenPeerRef.current.destroy();
            }
            screenPeers.forEach(({ peer }) => {
                if (peer) peer.destroy();
            });

            // Stop local stream tracks
            if (localStream) {
                localStream.getTracks().forEach(track => track.stop());
            }

            // Disconnect all peer connections
            peers.forEach(({ peer }) => {
                if (peer) {
                    try {
                        peer.destroy();
                    } catch (error) {
                        console.error('Error destroying peer:', error);
                    }
                }
            });

            // Notify server about leaving
            if (room_id && socket) {
                socket.emit('leave_room', {
                    room_id: room_id,
                    username: username
                });
            }

            // Clear all states
            // setIsHost(false);
            // setIsOwner(false);
            // setActivePopupIndex(null);
            // setMessageList([]);
            // setInMeetingNotification('');
            // setLocalStream(null);
            // setScreenShare(false);
            // setScreenRecording(false);
            // setHandRaise(false);
            // setPeers([]);
            // setHasJoined(false);






            screenShareRef.current = null
            isFirstRender.current = null
            setUserMessage('')
            setPreviouslyVideoMuted(false)
            notificationTimeoutRef.current = null
            setHandRaiseList([])
            currentScreenShareState.current = {
                stream: null,
                isSharing: false,
                previousVideoState: false
            }
            setIsHost(false)
            setIsOwner(false)
            setPin({
                type: "",
                peer_id: null,
                pinned: false,
            })
            setActivePopupIndex(null)
            setMessageList([])
            setInMeetingNotification('')
            // localVideoRef.current = null
            // setLocalStream(null)
            setWhiteBoard(false)
            setIsVideoMuted(false)
            setScreenShare(false)
            setScreenRecording(false)
            setIsAudioMuted(false)
            setHandRaise(false)
            setPeers(null)
            peersRef.current = null
            screenPeerRef.current = null
            setScreenPeers([])
            setScreenStream(null)
            setHasJoined(false)



            if (notificationTimeoutRef.current) {
                clearTimeout(notificationTimeoutRef.current);
            }

            socket.removeAllListeners();

            // // Original cleanup code
            // socket.off('receive_message');
            // socket.off('user_joined');
            // socket.off('user_left');
            // socket.off('user_joined_signal');
            // socket.off('receiving_returned_signal');
            // socket.off('user_screen_sharing');
            // socket.off('receiving_returned_screen_signal');
            // socket.off('user_stopped_screen_sharing');
            handleLeaveRoom();
        };

        // Add the event listener
        window.addEventListener('beforeunload', handleBeforeUnload);

        getMediaStream();

        socket.on('received_all_audio_mute', (data) => {
            setPeers((prevPeers) => {
                const newPeers = prevPeers.map(peer => {
                    return (
                        { ...peer, audioMuted: true }
                    )
                })

                return newPeers;
            });
            audioMute()
        });

        socket.on('received_all_video_mute', (data) => {
            setPeers((prevPeers) => {
                const newPeers = prevPeers.map(peer => {
                    return (
                        { ...peer, videoMuted: true }
                    )
                })

                return newPeers;
            });
            videoMute()
        });

        socket.on('received_video_mute', (data) => {
            setPeers((prevPeers) => {
                const newPeers = prevPeers.map(peer =>
                    peer.peer_id === data.peer_id
                        ? { ...peer, videoMuted: true }
                        : peer
                );

                return newPeers;
            });
        });

        socket.on('received_video_unmute', (data) => {
            setPeers((prevPeers) => {
                const newPeers = prevPeers.map(peer =>
                    peer.peer_id === data.peer_id
                        ? { ...peer, videoMuted: false }
                        : peer
                );

                return newPeers;
            });
        });

        socket.on('received_audio_unmute', (data) => {
            setPeers((prevPeers) => {
                const newPeers = prevPeers.map(peer =>
                    peer.peer_id === data.peer_id
                        ? { ...peer, audioMuted: false }
                        : peer
                );

                return newPeers;
            });
        });

        socket.on('received_audio_mute', (data) => {
            setPeers((prevPeers) => {
                const newPeers = prevPeers.map(peer =>
                    peer.peer_id === data.peer_id
                        ? { ...peer, audioMuted: true }
                        : peer
                );

                return newPeers;
            });
        });

        socket.on('received_target_video_mute', (data) => {
            // setPeers((prevPeers) => {
            //     const newPeers = prevPeers.map(peer =>
            //         peer.peer_id === data.peer_id
            //             ? { ...peer, videoMuted: true }
            //             : peer
            //     );

            //     return newPeers;
            // });

            if (data.peer_id === socket.id && !isVideoMuted) {
                videoMute()
            }
        });

        socket.on('received_target_audio_mute', (data) => {
            setPeers((prevPeers) => {
                const newPeers = prevPeers.map(peer =>
                    peer.peer_id === data.peer_id
                        ? { ...peer, audioMuted: true }
                        : peer
                );

                return newPeers;
            });

            if (data.peer_id === socket.id) {
                const audioTracks = localStream.getAudioTracks();
                setIsAudioMuted(true)

                audioTracks.forEach(track => {
                    track.stop();
                });

                audioTracks.forEach(track => {
                    localStream.removeTrack(track);
                });

                updatePeerTracks(true, isVideoMuted)
            }
        });

        socket.on('new_host_received', (data) => {
            setPeers((prevPeers) => {
                const newPeers = prevPeers.map(peer =>
                    peer.peer_id === data.peer_id
                        ? { ...peer, is_host: true }
                        : peer
                );

                return newPeers;
            });

            if (data.peer_id === socket.id) {
                setIsHost(true)
            }

            if (notificationTimeoutRef.current) {
                clearTimeout(notificationTimeoutRef.current);
            }

            // Set notification with the username who joined
            setInMeetingNotification(`${data?.username || 'A participant'} has been promoted to host`);

            // Set a new timeout to clear the notification
            notificationTimeoutRef.current = setTimeout(() => {
                setInMeetingNotification('');
                // Clear the ref after timeout
                notificationTimeoutRef.current = null;
            }, 3000);
        });

        socket.on('host_remove_received', (data) => {

            setPeers((prevPeers) => {
                const newPeers = prevPeers.map(peer =>
                    peer.peer_id === data.peer_id
                        ? { ...peer, is_host: false }
                        : peer
                );

                return newPeers;
            });

            if (data.peer_id === socket.id) {
                setIsHost(false)
            }

            if (notificationTimeoutRef.current) {
                clearTimeout(notificationTimeoutRef.current);
            }

            // Set notification with the username who joined
            setInMeetingNotification(`${data?.username || 'A participant'} has been removed as host`);

            // Set a new timeout to clear the notification
            notificationTimeoutRef.current = setTimeout(() => {
                setInMeetingNotification('');
                // Clear the ref after timeout
                notificationTimeoutRef.current = null;
            }, 3000);
        });

        socket.on('receive_message', (data) => {
            const timeFormatter = new Intl.DateTimeFormat('en-PK', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
                timeZone: 'Asia/Karachi'
            });
            const currentTime = timeFormatter.format(new Date());

            // Create the payload
            const messagePayload = {
                time: currentTime,
                message: data.message,
                username: data.username
            };

            // Update the local message list
            setMessageList((prevMessages) => [...prevMessages, messagePayload]);
        });

        socket.on('stop_whiteboard_received', (data) => {
            setPeers((prevPeers) => {
                const newPeers = prevPeers.map(peer =>
                    peer.peer_id === data.socket_id
                        ? { ...peer, sharing_whiteboard: false }
                        : peer
                );

                console.log(newPeers)

                return newPeers;
            });
        })

        socket.on('start_whiteboard_received', (data) => {
            setPeers((prevPeers) => {
                const newPeers = prevPeers.map(peer =>
                    peer.peer_id === data.socket_id
                        ? { ...peer, sharing_whiteboard: true }
                        : peer
                );

                console.log(newPeers)

                return newPeers;
            });
        })

        socket.on('user_started_screenshare_received', (data) => {
            setPeers((prevPeers) => {
                const newPeers = prevPeers.map(peer =>
                    peer.peer_id === data.socket_id
                        ? { ...peer, is_screensharing: true }
                        : peer
                );

                return newPeers;
            });
        })

        socket.on('user_stopped_screenshare_received', (data) => {
            setPeers((prevPeers) => {
                const newPeers = prevPeers.map(peer =>
                    peer.peer_id === data.socket_id
                        ? { ...peer, is_screensharing: false }
                        : peer
                );

                return newPeers;
            });
        })

        socket.on('receive_hand_raise', (data) => {

            const payload = {
                username: data.username,
                socket_id: data.socket_id,
            };

            // Use Set to ensure unique socket_ids, but preserve existing entries
            const uniqueHandRaises = Array.from(
                new Set([
                    ...handRaiseList.map(item => item.socket_id),
                    payload.socket_id
                ])
            ).map(socket_id =>
                handRaiseList.find(item => item.socket_id === socket_id) || payload
            );

            setHandRaiseList(prevList => {
                // Check if the new socket_id is already in the list
                const isNewEntry = !prevList.some(item => item.socket_id === payload.socket_id);

                // If it's a new entry, append it
                if (isNewEntry) {
                    return [...prevList, payload];
                }

                // If it's already in the list, return the previous list
                return prevList;
            });
        });

        socket.on('receive_lower_hand_raise', (data) => {
            setHandRaiseList(prevList => {
                const updatedHandRaises = prevList.filter(h => h.socket_id !== data.socket_id);

                return updatedHandRaises;
            });
        });

        socket.on('user_joined_signal', (data) => {
            const currentStream = currentScreenShareState.current.isSharing ?
                currentScreenShareState.current.stream :
                localStream;

            const peer = addPeer(data.signal, data.new_user_socket_id, currentStream, currentScreenShareState.current.isSharing)
            setPeers(users => [...users, {
                is_owner: data.is_owner,
                peer_id: data.new_user_socket_id,
                username: data.username,
                is_host: data.is_host,
                videoMuted: data.videoMuted,
                audioMuted: data.audioMuted,
                peer,
                is_screensharing: false,
                sharing_whiteboard: false,
            }]);

            if (notificationTimeoutRef.current) {
                clearTimeout(notificationTimeoutRef.current);
            }

            // Set notification with the username who joined
            setInMeetingNotification(`${data?.username || 'A participant'} has joined the room`);

            // Set a new timeout to clear the notification
            notificationTimeoutRef.current = setTimeout(() => {
                setInMeetingNotification('');
                // Clear the ref after timeout
                notificationTimeoutRef.current = null;
            }, 3000);
        });

        socket.on('user_joined', (data) => {

        });

        socket.on('receiving_returned_signal', (data) => {
            const item = peers.find(p => p.peer_id === data.socket_id)
            item.peer.signal(data.signal)

            const whiteBoardPeers = peers?.filter(item => item.sharing_whiteboard === true)

            if (whiteBoardPeers.length > 0) {
                socket.emit('request_paths', {
                    room_id: room_id,
                    username: username,
                    requester_socket_id: socket.id,
                    white_board_host_socket_id: whiteBoardPeers[0].peer_id
                });
            }
        });

        socket.on('kick_user_received', (data) => {
            if (data.peer_id === socket.id) {
                // alert('You have been removed from the meeting')
                handleLeaveRoom()
            }
            else {
                setPeers(currentPeers => {
                    const updatedPeers = currentPeers.filter(p => p.peer_id !== data.peer_id);

                    // Destroy the specific peer connection
                    const removedPeer = currentPeers.find(p => p.peer_id === data.peer_id);
                    if (removedPeer && removedPeer.peer) {
                        try {
                            removedPeer.peer.destroy();
                        } catch (error) {
                            console.error('Error destroying peer:', error);
                        }
                    }

                    return updatedPeers;
                });

                if (notificationTimeoutRef.current) {
                    clearTimeout(notificationTimeoutRef.current);
                }

                // Set notification with the username who left
                setInMeetingNotification(`${data?.username || 'A participant'} has been removed from the room`);

                // Set a new timeout to clear the notification
                notificationTimeoutRef.current = setTimeout(() => {
                    setInMeetingNotification('');
                    // Clear the ref after timeout
                    notificationTimeoutRef.current = null;
                }, 3000);
            }
        });

        socket.on('meeting_end_received', (data) => {
            handleLeaveRoom()
            // alert('Meeting has ended')
        });

        socket.on('user_left', (data) => {
            // Remove the peer when a user leaves
            setPeers(currentPeers => {
                const updatedPeers = currentPeers.filter(p => p.peer_id !== data.socket_id);

                // Destroy the specific peer connection
                const removedPeer = currentPeers.find(p => p.peer_id === data.socket_id);
                if (removedPeer && removedPeer.peer) {
                    try {
                        removedPeer.peer.destroy();
                    } catch (error) {
                        console.error('Error destroying peer:', error);
                    }
                }

                return updatedPeers;
            });

            if (notificationTimeoutRef.current) {
                clearTimeout(notificationTimeoutRef.current);
            }

            // Set notification with the username who left
            setInMeetingNotification(`${data?.username || 'A participant'} has left the room`);

            // Set a new timeout to clear the notification
            notificationTimeoutRef.current = setTimeout(() => {
                setInMeetingNotification('');
                // Clear the ref after timeout
                notificationTimeoutRef.current = null;
            }, 3000);
        });


        // Cleanup on component unmount
        return () => {
            console.log('left')

            window.removeEventListener('beforeunload', handleBeforeUnload);
            handleBeforeUnload();
        };
    }, [location.search]);

    return (
        <div>
            {room_id && (
                <>
                    <VideoConference
                        socket={socket}
                        room_id={room_id}
                        username={username}
                        localVideoRef={localVideoRef}
                        isVideoMuted={isVideoMuted}
                        isAudioMuted={isAudioMuted}
                        sendMessage={sendMessage}
                        toggleVideoMute={toggleVideoMute}
                        toggleAudioMute={toggleAudioMute}
                        peers={peers}
                        handleLeaveRoom={handleLeaveRoom}
                        messageList={messageList}
                        setMessageList={setMessageList}
                        userMessage={userMessage}
                        setUserMessage={setUserMessage}
                        setScreenShare={setScreenShare}
                        screenShare={screenShare}
                        toggleScreenShare={toggleScreenShare}
                        toggleScreenRecording={toggleScreenRecording}
                        setScreenRecording={setScreenRecording}
                        screenRecording={screenRecording}
                        handRaise={handRaise}
                        setHandRaise={setHandRaise}
                        toggleHandRaise={toggleHandRaise}
                        inMeetingNotification={inMeetingNotification}
                        setInMeetingNotification={setInMeetingNotification}
                        handRaiseList={handRaiseList}
                        setWhiteBoard={setWhiteBoard}
                        setHandRaiseList={setHandRaiseList}
                        handleHandRaise={handleHandRaise}
                        setIsHost={setIsHost}
                        isHost={isHost}
                        toggleWhiteBoard={toggleWhiteBoard}
                        muteUserMic={muteUserMic}
                        muteUserVideo={muteUserVideo}
                        kickUser={kickUser}
                        promoteToHost={promoteToHost}
                        demoteToViewer={demoteToViewer}
                        activePopupIndex={activePopupIndex}
                        setActivePopupIndex={setActivePopupIndex}
                        isOwner={isOwner}
                        whiteBoard={whiteBoard}
                        setIsOwner={setIsOwner}
                        endMeetingForAll={endMeetingForAll}
                        muteAllVideo={muteAllVideo}
                        muteAllMic={muteAllMic}
                        screenStream={screenStream}
                        setScreenStream={setScreenStream}
                        screenPeers={screenPeers}
                        setScreenPeers={setScreenPeers}
                        screenPeerRef={screenPeerRef}
                        screenShareRef={screenShareRef}
                        setPin={setPin}
                        pin={pin}
                        localStream={localStream}
                    />
                </>
            )}
        </div >
    );
}

export default Conversation;